import React from 'react'
import ReactDOM from 'react-dom'

export default function inputWithEdpSearch(WrappedComponent){
  return new React.Component({
    componentDidMount: function() {
      var input = $(ReactDOM.findDOMNode(this)).find("input");
      input.tooltipster({
        interactive: true,
        contentAsHTML: true,
        contentCloning: true,
        autoClose: false,
        trigger: 'custom',
        triggerOpen: {
          click: true,
          tap: true
        },
        triggerClose: {
          click: false,
          scroll: false,
          focus: true,
          blur: true
        }
      });

      input.on("focus", function(e) {
        e.preventDefault();
        var popup = document.createElement("div");
        ReactDOM.render(<EdpSearchPopup onInputChanged={this.props.onInputChange} tooltipRef={input} />, popup);

        var instance = $(e.target).tooltipster('instance');
        instance.content(popup).show();
      }.bind(this));
    },

    render: function() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  });
}