import React from 'react'
import _ from 'lodash'

import ToolFilters from 'components/ToolFilters'
import ToolSearchResultsTable from 'components/ToolSearchResultsTable'


import vex from 'vex-js'
import vexDialog from 'vex-dialog'

vex.registerPlugin(vexDialog)


class ToolSearcher extends React.Component{
  render() {
      return <div>
      <ToolSearchResultsTable   
            tools={this.state.tools} 
            parent={this} 
            analysis_usage={this.props.analysis_usage}
           > 
        </ToolSearchResultsTable>
    </div>
  }
  constructor (props){
      super(props);
      this.state = {
          tools: this.props.tools,
          tool_count: this.props.tool_count,
          num_pages: this.props.num_pages,
          filters: [
              {name: 'style', heading:"Basic Style", type:'radio', options: [
                {label: 'Ball Nose', value: 'ball nose'},
                {label: 'Chip Breaker (CR)', value: 'chip breaker (cr)'},
                {label: 'Chip Breaker (Square)', value: 'chip breaker (square)'},
                {label: 'Corner Radius', value: 'corner radius'},
                {label: 'Square End', value: 'square end'},
                {label: 'Tapered (Ball)', value: 'tapered (ball)'},
                {label: 'Tapered (Square)', value: 'tapered (square)'}],
              value:""},
              {name: 'performance', heading:"Performance", type:'radio', options: [
                {label: 'High Performance', value:"high_performance"},
                {label: 'General Use', value:"general"}],
              value:""},
              {name: 'coating', heading:"Coating", type:'radio', options: [
                  {label: 'Ti-Namite', value:"TN"},
                  {label: 'Ti-Namite-A', value:"TA"},
                  {label: "Ti-Namite-B", value:'TB'},
                  {label: 'Ti-Namite-C', value: 'TC'},
                  {label: "Uncoated", value: "UN"},
                  {label: "Ti-Namite-M", value:"TM", },
                  {label: "Ti-Namite-X", value: "TX"},
                  {label: "Amorphous Diamond", value: "AD" },
                  {label: "Di-NAMITE", value: "TD"}],
              value:""},
              {name: 'description', heading:"Description", type:'text', options: null, value:"", placeholder:"e.g. 11/64 ENDMILL 4FL SQ TA", typeahead: true},
              {name: 'series', heading:"Series", type:'text', options: null, value:"", typeahead: true},
              {name: 'flutes', heading:" # of Flutes", type:'text', options: null, value:""},
              {name: 'corner_radius', heading:"Corner Radius Size", type:'text', options: null,value:""},
              {name: 'cutting_diameter', heading:"Cutting Diameter", type:'text', options: null, value:""},
              {name: 'cutting_length', heading:"Cutting Depth", type:'text', options: null, value:""},
          ],
          current_page: this.props.initial_page,
          sort_by: null,
          sort_direction: 'asc',
      }
  }
  updatePage(page){
      var newState = this.state
      newState.current_page = page
      this.fetchTools(newState)
  }
  removeFilter(filter){
      filter.value = ""
      this.fetchTools({filters: this.replaceFilter(this.state.filters, filter)})

  }
  clearAll(){
      this.fetchTools({
          filters: _.map(this.state.filters, function(filter){
              filter.value = ""
              return filter;
          }),
          current_page: 1,
      })
  }
  sortBy(column){
      var newState = this.state
      if(column == newState.sort_by){
          newState.sort_direction = newState.sort_direction == "asc" ? "desc" : "asc"
      }
      newState.sort_by = column
      newState.current_page = 1

      this.fetchTools(newState)
  }
  queryData(state){
      var filter_query = _.reduce(_.map(_.filter(state.filters, function(filter){
          return !!filter.value
      }),function(filter){
          t = {}
          t[filter.name]= filter.value
          return t
      }),function(filter, memo){
          return _.extend(memo, filter)
      }, {})

      if(state.current_page != 1){
         filter_query['page'] = this.state.current_page
      }
      if(!_.isNull(state.sort_by)){
          filter_query['sort'] = state.sort_by
          filter_query['sort_direction'] = state.sort_direction
      }
      if(!!this.props.analysis_id){
          filter_query['analysis_id'] = this.props.analysis_id
      }
      return filter_query;
  }

  replaceFilter(filters, filter){
      return _.map(this.state.filters, function(state_filter){
            if(state_filter.name == filter.name){ return filter }
            return state_filter
    })
  }
  
  chooseFilter(filter, e){
    var that = this

    vex.dialog.confirm({
      message: filter.heading,
      input: that.createInput(filter),
      afterOpen: function(){
        if(filter.typeahead){
            $("#filter-text").keyup(function(){
                var filter_text_scope = this
                var query_data = that.queryData(that.state)
                query_data[filter.name] = $(this).val()
                query_data['attr'] = filter.name
                $.get(that.props.tool_options_path, query_data, function(data){
                      $("#search-results").removeClass("search-results--disabled")
                      $("#search-results").empty()
                    $("#search-results").append('<li class="search-result" data-result-id="'+$(filter_text_scope).val()+'">'+ $(filter_text_scope).val() +'</li>')
                    _.each(data, function(option){
                      $("#search-results").append('<li class="search-result" data-result-id="'+option+'">'+ option+'</li>')
                    })
                    $(".search-result").click(function(){
                        $("#filter-text").val($(this).data('result-id'))
                        $(this).closest('form').trigger('submit')
                    })
                    console.log(data)
                })
            })
        }
      },
      callback: function(data) {
        if(data === false){ return null } // if canceled ?

        var newState = that.state
        filter.value = data[filter.name]
        newState.filters =  that.replaceFilter(newState.filters, filter)
        that.fetchTools(newState);

        return null
      }
    });
  }

  fetchTools(state){
      if(state == undefined){state = this.state }

      var that = this
      $.get(this.props.search_path + '.json', this.queryData(state), function(data){
          state.tools = data.tools
          state.tool_count = data.count
          state.num_pages = Math.ceil(parseFloat(data.count) / 20)
          that.setState(state)
      }).error(function(err, msg){
          console.log(err)
          console.log(msg)
      })
  }
  createInput(filter){
      if(filter.type == 'radio'){ return this.createRadio(filter) }
      return this.createText(filter)
  }
  createText(filter){
      return '<div class="filter__text-group"><input type="text" id="filter-text" name="' + filter.name + '" value="'+ filter.value +'" placeholder="'+ (!!filter.placeholder ? filter.placeholder : "") +'"></input> <ul id="search-results" class="search-results search-results--disabled"></ul></div>'
  }
  createRadio(filter){
      return _.reduce(_.map(filter.options, function(option){
          return '<div class="filters__radio-group"><input type="radio" name="' + filter.name + '" value="'+option.value+ '" ' + (filter.value == option.value ? "checked" : "") + '></input> <label>' + option.label + '</label></div>'
      }),function(memo, i){
          return memo + i
      }, '')
  }
}

export default ToolSearcher