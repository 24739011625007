import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import TestToolReportNumberHelper from 'src/test_tool_report_number_helper'

export default class TestToolInfoSingleSelect extends React.Component{
  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).find("input")
                                 .blockInput(TestToolReportNumberHelper.blockInputConfigFor(this.props.valueType));
  }

  handleInputChange(e) {
    this.props.onInputChange(this.props.datakey, e.target.value);
  }

  handleInputFocus(e) {
    if (this.props.onHandleInputFocus !== undefined) {
      this.props.onHandleInputFocus(this.props.datakey);
    }
  }

  render() {
    var selectOptions = [];
    selectOptions.push(<option key="0"></option>); // Always include the blank option first

    _.each(this.props.options, function(option, idx){
      var optValue = Object.keys(option)[0];
      var optText = option[optValue];

      selectOptions.push(<option key={idx+1} value={optValue} >{optText}</option>);
    });

    var currentSelected = this.props.data[this.props.datakey];

    return  <div className="floating-label-input ttr-info-row__input">
              <div className="select select--ttr">
                <label className="floating-label-input__label" htmlFor={this.props.key}>{this.props.label}</label>
                <select value={currentSelected} onChange={this.handleInputChange} style={{ "width": "100%", "height":"29px"}}>
                  {selectOptions}
                </select>
              </div>
            </div>
  }
}
