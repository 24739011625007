import React from 'react'
import TestToolSectionHeader from 'components/test_tool_report/TestToolSectionHeader'
import TestToolCalcInputHeader from 'components/test_tool_report/TestToolCalcInputHeader'
import TestToolCalcSingleInput from 'components/test_tool_report/TestToolCalcSingleInput'
import TestToolCalcCycleTimeInput from 'components/test_tool_report/TestToolCalcCycleTimeInput'

export default class TestToolCalcSection extends React.Component{
  handleInputChange(k,v) {
    this.props.onCalcInputChange(k,v);
  }

  render() {
    return  <div className="row ttr-section">
              <TestToolSectionHeader text="Calculations" />

              <div className="col-10 ttr-section-content">

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Vendors"  />
                  <TestToolCalcSingleInput data={this.props.data} label="Vendors" readonlyValue="SGS" datakey="calc-input-vendor-sgs" onInputChange={this.handleInputChange} />
                  <TestToolCalcSingleInput data={this.props.data} label="Vendors" datakey="calc-input-vendor-other" onInputChange={this.handleInputChange} />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Cycle Time"  />
                  <TestToolCalcCycleTimeInput data={this.props.data} mins-datakey="calc-input-cycle-time-mins-sgs" secs-datakey="calc-input-cycle-time-secs-sgs"
                                              onInputChange={this.handleInputChange} />
                  <TestToolCalcCycleTimeInput data={this.props.data} mins-datakey="calc-input-cycle-time-mins-other" secs-datakey="calc-input-cycle-time-secs-other"
                                              onInputChange={this.handleInputChange} />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Number of parts to produce"  />
                  <TestToolCalcSingleInput data={this.props.data} label="Quantity" datakey="calc-input-quantity-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="Quantity" datakey="calc-input-quantity-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Hourly Shop Rate (Dollars)"  />
                  <TestToolCalcSingleInput data={this.props.data} label="Hourly Rate" datakey="calc-input-hourly-rate-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="Hourly Rate" datakey="calc-input-hourly-rate-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Total Milled or Driled Length per part (inches)"
                                           helpText="Axial depth (L) and radial width (M) effect total milled length because milling deeper and/or wider than the competitor typically reduces the number of passes and therefore the total milled length.  Adjust your entry on line C accordingly to maximize cost reductions." />
                  <TestToolCalcSingleInput data={this.props.data} label="Milled/Drilled Length" datakey="calc-input-milled-drilled-length-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"
                                           helpText="Enter Zero if using 'Cycle Time' function" />
                  <TestToolCalcSingleInput data={this.props.data} label="Milled/Drilled Length" datakey="calc-input-milled-drilled-length-other"
                                           onInputChange={this.handleInputChange} valueType="float.4"
                                           helpText="Enter Zero if using 'Cycle Time' function" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Revolutions Per Minute (RPM)"  />
                  <TestToolCalcSingleInput data={this.props.data} label="Speed" datakey="calc-input-rpm-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="Speed" datakey="calc-input-rpm-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Feed per Revolution (Inch)"  />
                  <TestToolCalcSingleInput data={this.props.data} label="Feed" datakey="calc-input-feed-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="Feed" datakey="calc-input-feed-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Cost of a New Tool (Dollars)"  />
                  <TestToolCalcSingleInput data={this.props.data} label="Tool Cost" datakey="calc-input-new-tool-cost-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="Tool Cost" datakey="calc-input-new-tool-cost-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Time to Change Tool (Minutes)"  />
                  <TestToolCalcSingleInput data={this.props.data} label="Change of Tool Time" datakey="calc-input-tool-change-time-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="Change of Tool Time" datakey="calc-input-tool-change-time-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Regrind Cost (Dollars)"
                                           helpText="Enter zero if not regrinding" />
                  <TestToolCalcSingleInput data={this.props.data} label="Regrind Cost" datakey="calc-input-tool-regrind-cost-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="Regrind Cost" datakey="calc-input-tool-regrind-cost-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="# of Parts Produced by a New Tool"  />
                  <TestToolCalcSingleInput data={this.props.data} label="New Tool Parts Produced" datakey="calc-input-new-tool-parts-produced-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="New Tool Parts Produced" datakey="calc-input-new-tool-parts-produced-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="# of Parts Produced by a Regrind"
                                           helpText="Enter zero if not regrinding" />
                  <TestToolCalcSingleInput data={this.props.data} label="Regrind Parts Produced" datakey="calc-input-regrind-parts-produced-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="Regrind Parts Produced" datakey="calc-input-regrind-parts-produced-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="# of Regrinds Available Per Tool"
                                           helpText="Enter zero if not regrinding" />
                  <TestToolCalcSingleInput data={this.props.data} label="# of Regrinds Available" datakey="calc-input-regrinds-available-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="# of Regrinds Available" datakey="calc-input-regrinds-available-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Axial Depth per pass (Milling) or per hole (Drilling)"
                                           helpText="When milling, if your axial depth exceeds the competitor, adjust your entry on line C accordingly to show fewer total inches of use.  As an example, if all other entries are equal but you cut twice as deep per pass, your total milled length would be half the competitors and your costs will be lower." />
                  <TestToolCalcSingleInput data={this.props.data} label="Axial Depth" datakey="calc-input-axial-depth-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="Axial Depth" datakey="calc-input-axial-depth-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Radial Width per pass when Milling (inch)"
                                           helpText="If your radial width exceeds the competitor, adjust your entry on line C accordingly to show fewer total inches of use.  As an example, if all other entries are equal but you cut twice as wide, your total milled length would be half the competitors and your costs will be lower." />
                  <TestToolCalcSingleInput data={this.props.data} label="Radial Width" datakey="calc-input-radial-width-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"
                                           helpText="Enter zero when drilling" />
                  <TestToolCalcSingleInput data={this.props.data} label="Radial Width" datakey="calc-input-radial-width-other"
                                           onInputChange={this.handleInputChange} valueType="float.4"
                                           helpText="Enter zero when drilling" />
                </div>

                <div className="row ttr-calc-row">
                  <TestToolCalcInputHeader text="Drill or End Mill Diameter (inch)"  />
                  <TestToolCalcSingleInput data={this.props.data} label="Diameter" datakey="calc-input-diameter-sgs"
                                           onInputChange={this.handleInputChange} valueType="float.4"/>
                  <TestToolCalcSingleInput data={this.props.data} label="Diameter" datakey="calc-input-diameter-other"
                                           onInputChange={this.handleInputChange} valueType="float.4" />
                </div>

              </div>
            </div>
  }
}
