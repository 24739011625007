import React from "react"
import AnalysisMaterialForm from 'components/AnalysisMaterialForm'

import vex from 'vex-js'
import vexDialog from 'vex-dialog'

vex.registerPlugin(vexDialog)

import _ from 'lodash'

class  MaterialSelection extends React.Component {
  render() {
    var that = this
    return <AnalysisMaterialForm
            action={this.props.material_path}
            selections={this.state.selections}
            authenticity_token={this.props.authenticity_token}
            images={this.props.images}
            parent={this} >
    </AnalysisMaterialForm>
  };
  constructor(props) {
    super(props);
    this.state = {
        selections: [
          { name: 'material_group',   heading: "Material Group",    values: this.props.options['material_groups'], value: '', current: true},
          { name: 'main_material',    heading: "Main Material",     values: this.props.options['main_materials'] , value: ''},
          { name: 'sub_material',     heading: "Sub Material",      values: this.props.options['sub_materials'],   value: ''},
          { name: 'material_example', heading: "Material Example",  values: this.props.options['material_examples'], value: ''},
          { name: 'hardness',         heading: "Hardness",          values: this.props.options['hardnesses'],      value: ''}
        ],
        current_selection: 'material_group'
    }
  };
  currentSelection = () => {
      return _.find(this.state.selections, function(selection){ return selection.current == true})
   };
  getMaterialSelection = (name) => {
    var foundSelection = _.find(this.state.selections, function(selection){ return selection.name == name})
    if(!foundSelection){ return {name: null, values: null, value: null } }
    return foundSelection
  };
  setMaterialSelection = (new_selection, selections) => {
    var selection_order = _.map(this.state.selections, 'name')
    var newSelections = _.reject(selections, function(selection){  return selection.name === new_selection.name })
    newSelections.push( new_selection )
    return _.sortBy(newSelections, function(selection){ return _.indexOf(selection_order, selection.name) })
  };
  chooseMaterial = (selected_material, value) => {
    var material = this.getMaterialSelection(selected_material.name)
    material.value = value
    var newSelections = this.setMaterialSelection(material, this.state.selections)

    this.setState({
      selections: newSelections,
    })

    this.updateSelectionValues()
  };
  selectionParams = () => {
      var that = this
      return _.reduce(_.reject(_.map(this.state.selections, function(selection){
          if(!selection.value){return null}
          var o = {}
          o[that.pluralize(selection.name) +'[id]'] = selection.value
          return o
      }), function(params){
          return _.isNull(params)
      }), function(memo, params){
          _.extend(memo, params)
          return memo

      }, {})
  };
  pluralize = (selection_name) => {
      return {
          'material_group': 'material_groups',
          'main_material': 'main_materials',
          'sub_material': 'sub_materials',
          'material_example': 'material_examples',
          'hardness': 'hardnesses',
      }[selection_name]
  };
  updateSelectionValues = () => {
    var that = this
    $.get(this.props.update_options_path, this.selectionParams(), function(data){
        that.setState({sections: _.map(that.state.selections, function(selection){
            selection.values = data[that.pluralize(selection.name)]
            if(!_.includes(selection.values, selection.value)){
                selection.value = null
            }
            if(selection.values.length == 1){
                selection.value = _.first(selection.values).id
            }
            return selection
            })
        })
        that.viewSelection(that.nextSelectionWithoutValue())
    })
  };
  previousSelection(){
    var selection_names = _.map(this.state.selections, 'name')
    var previous_index= _.indexOf(selection_names, this.currentSelection().name) - 1
    if(previous_index < 0  ) { previous_index = 0 }

    return this.getMaterialSelection(selection_names[previous_index])
  };
  nextSelection(){
    var selection_names = _.map(this.state.selections, 'name')
    var next_index = _.indexOf(selection_names, this.currentSelection().name) + 1
    if(next_index > selection_names.length - 1 ) { next_index = selection_names.length - 1  }

      return this.getMaterialSelection(selection_names[next_index])
  };
  nextSelectionWithoutValue(){
    var selection_names = _.map(this.state.selections, 'value')
    var next_index = _.indexOf(selection_names, null)
    if(next_index > selection_names.length - 1 || next_index == -1) { next_index = selection_names.length - 1  }

      return this.getMaterialSelection(this.state.selections[next_index].name)
  };
    viewSelection = (selection) => {
    var that = this
    this.setState({selections: _.map(that.state.selections, function(mselection){
      mselection.current = false
      if(mselection === selection){
        mselection.current=true
      }
      return mselection
      })
    })
  };
  searchExample = () => {
      var that = this
      vex.defaultOptions.className = 'vex-theme-default'
      vex.dialog.open({
          buttons: [
            $.extend({}, vex.dialog.buttons.YES, { text: 'Cancel', className: 'vex-dialog-button-primary' }),
          ],
          afterOpen:function(vexinstance){
              var vexScope = this
              $("#search-text").keyup(function(){
                  $("#search-results").removeClass("search-results--disabled")
                  $.get(that.props.search_example_path + ".json", { name: $(this).val() },  function(data){
                      $("#search-results").empty()
                      _.each(data, function(result){
                          $("#search-results").append('<li class="search-result" data-result-id="'+result.id+'">'+ result.display_name+'</li>')
                      })
                      $(".search-result").click(function(e){
                         that.chooseSearchResult($(this).data('result-id'))
                         /* $("#chosen-example-result").val($(this).data('result-id')) */
                         // $(this).closest('form').submit()
                         vexScope.close()
                      })
                  })
              })
          },
          /*callback: function(data){
            console.log("in vex callback")
              if(data == false) {return null}
              that.chooseSearchResult(data.chosen_example_result)
          },*/
          message: "Search Example",
          input: ['<div class="search-example field">',
                    '<input id="search-text" type="text" name="search_text" autocomplete="off" ></input>',
                    '<input id="chosen-example-result" name="chosen_example_result" value="testva" type="hidden" ></input>',
                    '<ul id="search-results" class="search-results search-results--disabled"></ul>',
                    '</div>'].join(''),
      })
  };
  chooseSearchResult = (id) => {
      this.setState({selections: _.map(this.state.selections, function(selection){
          selection.value = selection.name == 'material_example' ? id : null
          return selection
      })})
      this.updateSelectionValues()
  };
}

export default MaterialSelection;