import React from 'react'
import ReactDOM from 'react-dom'

export default class TestToolCalcInputHeader extends React.Component{
  componentDidMount() {
    if (this.props.helpText) {
      var contentVariant = "";

      if (this.props.helpText.length > 50) {
        contentVariant = "ttr-help-content--wide";
      }

      $(ReactDOM.findDOMNode(this))
        .find(".ttr-calc-row-header-help")
        .tooltipster({
          content: '<div class="ttr-help-content '+contentVariant+'"><p>'+this.props.helpText+'</p></div>',
          contentAsHTML: true
        });
    }
  }

  render() {
    var hasHelp = (this.props.helpText !== undefined);
    return  <div className="col-6 ttr-calc-row__header">
              <p className="sixth-headline">{this.props.text}</p>
              <div className="ttr-calc-row-header-help">
                {hasHelp ?
                  (<svg className="ttr-help-icon" width="16" height="16"/>)
                  :
                  (" ")
                }
              </div>
            </div>
  }
}