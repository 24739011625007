import React from 'react'

class SalesDashboardNewUsersPageButton extends React.Component{
  handleClick(e) {
    var newPage = 1;

    if (this.props.direction == "back") {
      newPage = this.props.usersData.currentPage - 1;

      if (newPage <= 0) newPage = 1;
    }

    if (this.props.direction == "forward") {
      newPage = this.props.usersData.currentPage + 1;
    }

    this.props.onPageButtonClicked(newPage);
  }

  render() {
    var buttonClass = (this.props.direction == "back") ? "new-users-f-b-buttons__b-button" :
                                                         "new-users-f-b-buttons__f-button";

    var buttonDisabled = false;

    if (this.props.direction == "back") {
      if (this.props.usersData.currentPage == 1) {
        buttonDisabled = true;
      }
    }

    if (this.props.direction == "forward") {
      if (this.props.usersData.remainingUsers == 0) {
        buttonDisabled = true;
      }
    }

    return <button className={"new-users-f-b-buttons " + buttonClass} disabled={buttonDisabled} onClick={this.handleClick.bind(this)} ></button>
  }

}

class SalesDashboardNewUsers extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      currentPage: this.props.initialData.thisPage,
      totalPages: this.props.initialData.totalPages,
      numDays: this.props.numDays,
      remainingUsers: this.props.initialData.remaining,
      totalUsers: this.props.initialData.total,
      users: this.props.initialData.users.slice()
    }
  }

  fetchUserData(numDays, page) {
    $.ajax({
      url: this.props.apiPath + ".json",
      data: "numDays="+numDays+"&page="+page,
      dataType: 'json',
      cache: false,
      success: function(apiData) {
        this.setState({
          currentPage: apiData.thisPage,
          totalPages: apiData.totalPages,
          numDays: numDays,
          remainingUsers: apiData.remaining,
          totalUsers: apiData.total,
          users: apiData.users.slice()
        });
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.apiPath, status, err.toString());
      }.bind(this)
    });
  }

  handlePeriodSelectChangenumDays(e) {
    this.fetchUserData(e.target.value, 1);
  }

  handlePageButtonClicked(page) {
    this.fetchUserData(this.state.numDays, page);
  }

  handleDownloadClick(e) {
    var form = $(e.target).closest("form");

    form.append($("<input>").attr("type", "hidden").attr("name", "numDays").val(this.state.numDays));
  }

  render() {
    var users = this.state.users.map(function(user, index){
      var country = user.country;
      var state = (user.country == "US") ? user.state : "";
      var zip = (user.country == "US") ? user.zip : "";

      return  <tr key={index}>
                <td>{user.email}</td>
                <td>{zip}</td>
                <td>{state}</td>
                <td>{country}</td>
              </tr>
    });

    var currentPageText = "Page " + this.state.currentPage + " of " + this.state.totalPages;

    return  <div className="row l-80 l-center new-users-container">
              <div className="row">
                <div className="col-6">
                  <span className="tertiary-headline tertiary-headline--left mtl">New Users</span>
                </div>
                <div className="col-6">
                  <div className="l-right field">
                    <div className="select">
                      <select value={this.state.numDays} onChange={this.handlePeriodSelectChangenumDays.bind(this)}>
                        <option value="1">Last 24 hours</option>
                        <option value="7">Last 7 days</option>
                        <option value="30">Last 30 days</option>
                        <option value="60">Last 60 days</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <table className="table table--striped sales-dash-table sales-dash-table--new-users mts">
                <thead>
                  <tr>
                    <td>Email</td>
                    <td>Zip</td>
                    <td>State</td>
                    <td>Country</td>
                  </tr>
                </thead>
                <tbody>
                  {users}
                </tbody>
              </table>

              <div className="row">
                <div className="col-6">
                  <form id="test-tool-report-export" action="/sales/download_users" acceptCharset="UTF-8" method="post">
                    <input name="utf8" type="hidden" value="✓" />
                    <input type="hidden" name="authenticity_token" value={this.props.authenticityToken} />
                    <button id="users-download-button" className="button active" onClick={this.handleDownloadClick.bind(this)}>Download</button>
                  </form>

                </div>
                <div className="col-6">
                  <div className="new-users-page-indicator">
                    <p>{currentPageText}</p>
                  </div>
                  <div className="l-right new-users-f-b-buttons" >
                    <SalesDashboardNewUsersPageButton direction="back" usersData={this.state} onPageButtonClicked={this.handlePageButtonClicked.bind(this)} />
                    <SalesDashboardNewUsersPageButton direction="forward" usersData={this.state} onPageButtonClicked={this.handlePageButtonClicked.bind(this)} />
                  </div>
                </div>
              </div>
            </div>
  }
}

export default SalesDashboardNewUsers