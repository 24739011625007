import React from 'react'
import ReactDOM from 'react-dom'
import inputWithEdpSearch from 'components/test_tool_report/inputWithEdpSearch'
import inputWithDatePick from 'components/test_tool_report/inputWithDatePick'
import TestToolReportNumberHelper from 'src/test_tool_report_number_helper'

export default class TestToolInfoSingleInput extends React.Component{
  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).find("input")
                                 .blockInput(TestToolReportNumberHelper.blockInputConfigFor(this.props.valueType));
  }

  handleInputChange(e) {
    this.props.onInputChange(this.props.datakey, e.target.value);
  }

  handleInputFocus(e) {
    if (this.props.onHandleInputFocus !== undefined) {
      this.props.onHandleInputFocus(this.props.datakey);
    }
  }

  render() {
    return  <div className="floating-label-input ttr-info-row__input">
              <label className="floating-label-input__label" htmlFor={this.props.key}>{this.props.label}</label>
              <input type="text" name={this.props.datakey} id={this.props.datakey} onChange={this.handleInputChange.bind(this)} onFocus={this.handleInputFocus.bind(this)} />
            </div>
  }
}

var TestToolInfoSingleInputWithEdpSearch = inputWithEdpSearch(TestToolInfoSingleInput);
var TestToolInfoSingleInputWithDatePick = inputWithDatePick(TestToolInfoSingleInput);
