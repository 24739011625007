import React from "react"

class MaterialSelector extends React.Component{
  render() {
    var selection = this.props.selection
    var that = this
    return <div>
        <input type="hidden" name={selection.name} value={selection.value} ></input>
        <div className="material_selection__current" name={selection.name} value={selection.value} >
          {
            selection.values.map(function(value){
              return <div className="material_selection__option" key={value.id} data-id={value.id} onClick={that.selectValue.bind(that)}> {value.display_name || value.name } </div>
            })
          }
        </div>
        { selection.name == 'material_group' ? <a href="#" className="example-link" onClick={this.searchExample.bind(this)}> Search Example </a> : "" }
    </div>
  };
  selectValue(e){
    this.props.parent.chooseMaterial(this.props.selection, $(e.target).closest(".material_selection__option").data("id"))
  };
  searchExample(e){
      e.preventDefault()
      this.props.parent.searchExample()
  };

  constructor(props){
    super(props);
  }

}

export default MaterialSelector;