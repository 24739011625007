var UnitSelector = createReactClass({
  render: function() {
    return <div className="unit-selector mts mbm">
      <input type="radio" id="imperial" name="usage[units]" value="imperial" checked={this.state.selection =='imperial'} onChange={this.handleChange} disabled={this.props.disable}/>
      <label htmlFor="imperial">Inch</label>
      <input type="radio" id="metric" name="usage[units]" value="metric" checked={this.state.selection == 'metric'} onChange={this.handleChange} disabled={this.props.disable}/>
      <label htmlFor="metric">Metric</label>
      { this.props.disable &&
        <input type="hidden" name="usage[units]" value={this.state.selection} />
      }
    </div>
  },
  handleChange: function(e) {
    var units = e.target.value;
    this.setState({
      selection: units
    });
    PubSub.publish('units_changed', units);
  },
  getInitialState: function(){
    return {
      selection: this.props.selection
    };
  }
});

