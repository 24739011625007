import React from 'react'
import TestToolInfoSingleInput from 'components/test_tool_report/TestToolInfoSingleInput'
import TestToolInfoSingleSelect from 'components/test_tool_report/TestToolInfoSingleSelect'
import TestToolInfoSingleInputWithDatePick from 'components/test_tool_report/TestToolInfoSingleInput'
import TestToolInfoSingleInputWithEdpSearch from 'components/test_tool_report/TestToolInfoSingleInput'
import TestToolSectionHeader from 'components/test_tool_report/TestToolSectionHeader'

export default class TestToolInfoSection extends React.Component{
  handleInputChange(k,v) {
    this.props.onInfoInputChange(k,v);
  }

  render() {
    return  <div className="row ttr-section">
              <TestToolSectionHeader text="Information" />

              <div className="col-10 ttr-section-content--info">
                <div className="row ttr-info-row">
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="SGS Rep" datakey="sgs-rep-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="Customer" datakey="customer-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="Contact" datakey="contact-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                </div>
                <div className="row ttr-info-row">
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="Phone" datakey="phone-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="Distributor" datakey="distributor-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                  <div className="col-4">
                    <TestToolInfoSingleInputWithDatePick data={this.props.data} label="Test Date" datakey="test-date-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                </div>
                <div className="row ttr-info-row">
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="Test PO Number" datakey="test-po-number-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                  <div className="col-4">
                    <TestToolInfoSingleInputWithEdpSearch data={this.props.data} label="SGS Product" datakey="sgs-product-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="Comparable Product" datakey="comparable-product-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                </div>
                <div className="row ttr-info-row">
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="Material Type" datakey="material-type-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="Hardness" datakey="hardness-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="Coolant" datakey="coolant-input" onInputChange={this.handleInputChange.bind(this)} />
                  </div>
                </div>
                <div className="row ttr-info-row">
                  <div className="col-4">
                    <TestToolInfoSingleSelect data={this.props.data} label="Material" datakey="material-input" onInputChange={this.handleInputChange.bind(this)}
                      options={[{"Steels":"Steels"},
                                {"Stainless Steels":"Stainless Steels"},
                                {"Cast Iron":"Cast Iron"},
                                {"High Temp Alloys":"High Temp Alloys"},
                                {"Titanium":"Titanium"},
                                {"Non-Ferrous":"Non-Ferrous"},
                                {"Plastics/Composites":"Plastics/Composites"},
                                {"Hardened Steels":"Hardened Steels"}
                              ]}
                    />
                  </div>
                  <div className="col-4">
                    <TestToolInfoSingleInput data={this.props.data} label="Material Sub Cat. (Ex: 4140)" datakey="material-sub-cat-input" onInputChange={this.handleInputChange.bind(this)}/>
                  </div>
                  <div className="col-4">
                    <TestToolInfoSingleSelect data={this.props.data} options={{}} label="Location" datakey="location-input" onInputChange={this.handleInputChange.bind(this)}
                      options={[{"KSPT":"KSPT"},
                                {"KSPTE":"KSPTE"},
                                {"KSTH":"KSTH"},
                              ]}
                    />
                  </div>
                </div>
              </div>
            </div>
  }
}