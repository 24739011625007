import React, { useState, useEffect } from 'react'
import { useTable, useFilters, useSortBy, usePagination, } from 'react-table'
import _ from 'lodash'

const filterLabelStyle = { padding: '5px 10px', display: 'inline-block', fontSize: '11px'}
const filterFieldStyle = { width: '100%', padding: '.2em', borderRadius: '3px', display: 'block', border: 'lightblue 1px solid', fontSize: '11px', textTransform: 'none'}

function ResultCounts({count}){
  return <div className="search_results__meta">
    <div className="tool__results__count sixth-headline txt-right">Number of Results:<br /><span className="txt-blue txt-14">{count}</span></div>
  </div>
}

function ToolSearchResultsTable(props) {

  const columns = React.useMemo( () => [
    {Header: "EDP", accessor: "edp"},
    {Header: "Description", accessor: "description"},
    {Header: <span> D<sub>1</sub><br/>Cutting<br/> Diameter</span>, accessor: "cutting_diameter_display", disableFilters: true},
    {Header: <span> D<sub>2</sub><br/>Shank<br/> Diameter</span>, accessor: "shank_diameter_display", disableFilters: true},
    {Header: <span> L<sub>1</sub><br/>Overall<br/> Length</span>, accessor: "oal_display", Filter: SelectColumnFilter,  filter_label: "Overall Length"},
    {Header: <span> L<sub>2</sub><br/>Length<br/> Of Cut</span>, accessor: "length_cut_display",  Filter: SelectColumnFilter, filter_label: "Length Of Cut"},
    {Header: <span> L<sub>3</sub><br/>Reach</span>, accessor: "reach_display",  Filter: ReachFilter, filter_label: "Reach", filter: isReach},
    {Header: <span>Corner<br/ > Radius</span>, accessor: "corner_radius_display", Filter: SelectColumnFilter, filter_label: "Corner Radius"},
    {Header: "Series", accessor: "series", Filter: SelectColumnFilter},
    {Header: "Coating", accessor: "coating", Filter: SelectColumnFilter},
    {Header: "Performance", accessor: "high_performance", Filter: BooleanSelectColumnFilter,  Cell: ({cell}) => {return cell.value ? "High Performance" : "General Purpose"}, filter_label: 'Performance', true_option: 'High Performance', false_option: 'General Purpose' },
    {Header: <span>Helix<br />Angle</span>, accessor: "helix_angle", Filter: SelectColumnFilter, filter_label: "Helix Angle"},
    {Header: <span>Point<br />Angle</span>, accessor: "point_angle", Filter: SelectColumnFilter, filter_label: "Point Angle"},
    {Header: <span>Number of <br />Flutes</span>, accessor: "number_of_flutes", Filter: SelectColumnFilter, filter_label: "Number of Flutes"},
    {Header: "Coolant", accessor: "int_coolant", Filter: BooleanSelectColumnFilter, filter_label: "Coolant", Cell: ({cell}) => {return cell.value ? "Internal" : "External"}, true_option: 'Internal', false_option: 'External' },
  
  ])

  const has_point_angle = _.some(props.tools, function(tool){return tool.point_angle > 0;})
  const has_corner_radius = _.some(props.tools, function(tool){return tool.corner_radius > 0;})
  const has_reach = _.some(props.tools, function(tool){return tool.reach > 0;})
  const has_int_coolant = _.some(props.tools, function(tool){return tool.int_coolant})

  let hidden = [];
  // hidden.push( "cutting_diameter_display")  // we don't need it in table
  !has_point_angle && hidden.push( "point_angle");
  !has_corner_radius && hidden.push( "corner_radius");

  return (
    <div>
      <Table
        columns={columns}
        data={props.tools}
        hidden={hidden}/>
    </div>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length
  return (
    <input
      style={filterFieldStyle}
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ...`}
    />
  )
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  // Render a multi-select box
  return (
    <select
      style={filterFieldStyle}
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function isReach(rows, id, filterValue) {
  const trueOrFalse = filterValue == "true" 
  return rows.filter(row => {
    const rowValue = parseFloat(row.original['reach'])
    return (rowValue > 0)  == trueOrFalse
  })
}


function ReachFilter({
  column: { filterValue, setFilter, preFilteredRows, id},
}) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(parseFloat(row.original['reach']) > 0 )
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  // Render a multi-select box
  return (
    <select
      style={filterFieldStyle}
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option ? 'Reach' : 'No Reach'}
        </option>
      ))}
    </select>
  )
}



function BooleanSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, true_option, false_option },
}) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  // Render a multi-select box
  return (
    <select
      style={filterFieldStyle}
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option ? true_option : false_option}
        </option>
      ))}
    </select>
  )
}

// Our table component
function Table({ columns, data, hidden }) {
  const [selectedTool, setSelectedTool] = useState('')
  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,  //instead of using 'rows' just use page only rows for active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, selectedRowIds, }
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {hiddenColumns: hidden},
      //autoResetSelectedRows: !skipReset,
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
  )


  return (
    <>
    <ResultCounts count={rows.length} />
    <div className="secondary-headline">Best Performance Listed First</div>
    <p/>
    <div>
      {headerGroups.map(headerGroup =>
        headerGroup.headers.map(column => {
            if (column.canFilter){
              return <span >
                <label style={filterLabelStyle}>
                {column.filter_label ? column.filter_label : column.Header}
                {column.render('Filter')}
                </label>
              </span>
            }
        })
      )}
    </div>
    <p/>
    

    <input type="hidden"  name="id" value={selectedTool} />
    <div className="table-wrapper scrollable">
      <table className="table table--striped summary__table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}
              className="summary__table__heading">
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <div>
                  <span {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                  </span>
                  <span>{column.isSorted ? (column.isSortedDesc ? String.fromCharCode(8593) : String.fromCharCode(8595)) : '' }</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className={row.values['edp'] == selectedTool ? 'filter__tool--selected ' : ''}
                {...row.getRowProps( {onClick: () => setSelectedTool(row.values['edp']) } ) } >
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>

    <div className="pagination">
      <button onClick={(e) => {e.preventDefault(); gotoPage(0);}} disabled={!canPreviousPage}>
        {'<<'}
      </button>{' '}
      <button onClick={(e) => {e.preventDefault(); previousPage();}} disabled={!canPreviousPage}>
        {'<'}
      </button>{' '}
      <button onClick={(e) => {e.preventDefault(); nextPage();}} disabled={!canNextPage}>
        {'>'}
      </button>{' '}
      <button onClick={(e) => {e.preventDefault(); gotoPage(pageCount - 1);}} disabled={!canNextPage}>
        {'>>'}
      </button>{' '}
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>{' '}
      </span>
      <select
        value={pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value))
        }}
      >
        {[10, 20, 30, 40, 50].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>

      {/*
      <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
              filters,
              selectedRowIds: selectedRowIds,
              selectedTool,
            },
            null,
            2
          )}
        </code>
      </pre>
      */}
      <br />
    </>
  )
}

export default ToolSearchResultsTable