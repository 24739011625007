// utility jQuery plugins
// Thanks to:  http://therealmofcode.com/posts/2014/03/where-is-keypress-event-chrome-android.html
// Modified to accept a "validString" parameter to check the entire string, not just the
// characters. This way we can check for only integers, or even check for things like
// 2.0 vs. 2.1.2.1.222
$.fn.blockInput = function (options)
{
  // find inserted or removed characters
  function findDelta(value, prevValue)
  {
    var delta = '';

    for (var i = 0; i < value.length; i++) {
      var str = value.substr(0, i) +
      value.substr(i + value.length - prevValue.length);

      if (str === prevValue) delta =
        value.substr(i, value.length - prevValue.length);
    }

    return delta;
  }

  function isValidChar(c)
  {
    return new RegExp(options.validChars).test(c);
  }

  function isValidString(str)
  {
    for (var i = 0; i < str.length; i++)
      if (!isValidChar(str.substr(i, 1))) return false;

    if (options.validString) {
      if (! new RegExp(options.validString).test(str)) {
        return false;
      }
    }

    return true;
  }

  this.filter('input,textarea').on('input', function ()
  {
    var val = this.value,
        lastVal = $(this).data('lastVal');

    // get inserted chars
    var inserted = findDelta(val, lastVal);
    // get removed chars
    var removed = findDelta(lastVal, val);
    // determine if user pasted content
    var pasted = inserted.length > 1 || (!inserted && !removed);

    if (pasted)
    {
      if (!isValidString(val)) this.value = lastVal;
    }
    else if (!removed)
    {
      if (!isValidChar(inserted)) this.value = lastVal;
      if (!isValidString(val)) this.value = lastVal;
    }

    // store current value as last value
    $(this).data('lastVal', this.value);
  }).on('focus', function ()
  {
    $(this).data('lastVal', this.value);
  });

  return this;
};

