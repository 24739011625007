import React from 'react'
import ReactDOM from 'react-dom'

export default class TTRDatePickPopup extends React.Component{
  handlePopupClose(e) {
    this.props.tooltipRef.tooltipster('close');
  }

  handleDateSelected(date) {
    this.props.onInputChanged("test-date-input", date);
    $(this.props.tooltipRef.context).find('.floating-label-input__label').addClass("floating-label-input__label--float");
    this.props.tooltipRef.val(date);
    this.props.tooltipRef.tooltipster('close');
  }

  componentDidMount() {
    var field = $(ReactDOM.findDOMNode(this)).find(".ttr-test-date-picker__cal");
    var picker = new Pikaday({
      onSelect: function(date) {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();

        this.handleDateSelected(
          [
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd,
            date.getFullYear()
          ].join('-')
        );
      }.bind(this)
    });

    field.append(picker.el);
  }

  render() {
    return  <div className="ttr-test-date-picker">
              <a className="ttr-test-date-picker__close" onClick={this.handlePopupClose.bind(this)}>X</a>
              <div className="ttr-test-date-picker__cal"></div>
            </div>
  }
}
