import React from 'react'

export default class TestToolSectionHeader extends React.Component{
  render() {
    return  <div className="col-2 ttr-section-header">
              <p>{this.props.text}</p>
              <hr />
            </div>
  }
}
