import React from 'react'
import ReactDOM from 'react-dom'
import TestToolReportNumberHelper from 'src/test_tool_report_number_helper'

export default class TestToolResultRow extends React.Component{
  defaultOrData(datakeyRef) {
    var value = this.props.data[this.props[datakeyRef]];

    if (value === undefined || value.length == 0) {
      return TestToolReportNumberHelper.display(this.props.defaultValue, this.props.valueType)
    }

    return TestToolReportNumberHelper.display(value, this.props.valueType)
  }

  componentDidMount() {
    if (this.props.helpText) {
      $(ReactDOM.findDOMNode(this))
        .find(".ttr-result-row-header-help")
        .tooltipster({
          content: '<div class="ttr-help-content"><p>'+this.props.helpText+'</p></div>',
          contentAsHTML: true
        });
    }
  }

  render() {
    var hasHelp = (this.props.helpText !== undefined);

    return  <div className="row ttr-result-row">
              <div className="col-6 ttr-result-row__header prs">
                <p className="sixth-headline">{this.props.label}</p>
                <div className="ttr-result-row-header-help">
                  {hasHelp ?
                    (<svg className="ttr-help-icon" width="16" height="16"/>)
                    :
                    (" ")
                  }
                </div>
              </div>
              <div className="col-3 ttr-result-row__value">
                <p>{this.defaultOrData("datakey-sgs")}</p>
              </div>
              <div className="col-3 ttr-result-row__value">
                <p>{this.defaultOrData("datakey-other")}</p>
              </div>
            </div>
  }
}