var UsageFormSwitcher = createReactClass({
  render: function() {
      return <div className="toggle-group">
        <input type="radio"className="toggle__input" id="toggle_endmill" value="endmill" onChange={this.switchForm} checked={this.checked('endmill')} disabled={this.props.disable} />
        <label htmlFor="toggle_endmill" className={this.inputClass('endmill')}>Endmills</label>
        <input className="toggle__input" type="radio" id="toggle_drill" value="drill" onChange={this.switchForm} checked={this.checked('drill')} disabled={this.props.disable} />
        <label htmlFor="toggle_drill" className={this.inputClass('drill')}>Drills</label>
    </div>
  },
  switchForm: function(e){
      this.setState({current_tool: e.target.value})
      $("section.usage__form-parts").removeClass("active")
      $("section.usage__form-parts").addClass("disabled")

      $("section.usage__" + e.target.value).removeClass('disabled')
      $("section.usage__" + e.target.value).addClass('active')

      $(".usage__form-parts").find("input, select").prop("disabled", true)
      $(".usage__form-parts").find("input:visible, select:visible").prop("disabled", false)
      $(".usage__form-parts").find('[type="hidden"]').prop("disabled", false)
      activateNext()
  },
  inputClass: function(tool_name){
      return 'toggle__label'
          + (this.state.current_tool == tool_name ? ' toggle__label--active' : '')
          + (this.props.disable ? ' toggle__label--disabled' : '')
  },
  componentDidMount: function(){
    $(".usage__form-parts").find("input, select").not('[type="hidden"]').prop("disabled", true)
    $(".usage__form-parts").find("input:visible, select:visible").not('[type="hidden"]').prop("disabled", false)
    PubSub.subscribe( 'inputs_mounted', this.inputsMounted)
  },
  inputsMounted: function(){
    $(".usage__form-parts").find("input, select").not('[type="hidden"]').prop("disabled", true)
    $(".usage__form-parts").find("input:visible, select:visible").not('[type="hidden"]').prop("disabled", false)
  },
  getInitialState: function(){
      return { current_tool: this.props.tool_type ? this.props.tool_type : 'endmill' }
  },
  checked: function(toolType){
      return toolType == this.state.current_tool ? true : false
  }
})
