export default class TestToolReportNumberHelper {
  // Private
  _isBlank(value) {
    if (value === undefined || value.length == 0)
      return true;

    return false;
  }
  
    static display(srcValue, type) {
        if(isNaN(srcValue)) return "ERR";

      // integer types
      if (type == "int") {
        return parseInt(srcValue).toString();
      }

      // float types
      if (RegExp(/^float\.\d+$/).test(type) == true) {
        var precision = type.split(".")[1];

        return parseFloat(srcValue).toFixed(precision);
      }

      if (type == "currency") {
        return '$' + parseFloat(srcValue).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');;
      }

      // percent types
      if (RegExp(/^percent\.\d+$/).test(type) == true) {
        var precision = type.split(".")[1];

        return parseFloat(srcValue).toFixed(precision) + '%';
      }

    }

    static convert(srcValue, type) {
      if (_isBlank(type)) return srcValue;

      // Convert value to integer
      if (type == "int"){
        return _isBlank(srcValue) ? 0 : parseInt(srcValue);
      }

      // Convert value to Float.
      if (RegExp(/^float\.\d+$/).test(type) == true) {
        var precision = type.split(".")[1];

        return _isBlank(srcValue) ? parseFloat(0.00000000.toFixed(precision)) : parseFloat(parseFloat(srcValue).toFixed(precision))
      }
    }

    static blockInputConfigFor(valueType) {
      var config = {};

      // Match integers: 1, 2, 10, 1032, etc
      if (valueType == "int"){
        return {validChars: "[0-9]"};
      }

      // Match floats: .1, 0.123, 1.000, etc
      if (RegExp(/^float\.\d+$/).test(valueType) == true) {
        return { validChars: '[0-9.]', validString: '^([0-9]*\.?([0-9]*))$' }
      }

      // No configuration for free-text or if no blocking input configuration is specified
      return {};
    }
  }
