import React from 'react'
import _ from 'lodash'

export default class CustomToolCalculation extends React.Component{
  render(){
      return <tr>
            <td>{this.state.sfm}</td>
            <td><input name="rpm" value={this.state.rpm} onChange={this.updateCalculation.bind(this)}></input></td>
            <td><input name="ipm" value={this.state.ipm} onChange={this.updateCalculation.bind(this)}></input></td>
            <td>{this.props.tool.type == "endmill" ? this.state.chip_load : this.state.ipr}</td>
            {this.material_removal_cell()}
            {this.horsepower_cell()}
    </tr>
  }
  material_removal_cell(){
      if(this.props.tool.type == "endmill"){
          return <td>{this.state.material_removal_rate}</td>
      }
      // return ""
      return null
  }
  horsepower_cell(){
      if(this.props.tool.type == "endmill"){
          return <td>{this.state.horse_power}</td>
      }
      // return ""
      return null
  }
  constructor(props){
      super(props)
      this.state={
          sfm: "NA",
          rpm: "NA",
          ipm: "NA",
          ipr: "NA",
          chip_load: "NA",
          material_removal_rate: "NA",
          horse_power: "NA",
      }
  }
  updateCalculation(e){
      var that = this
      var new_state = this.state
      new_state[e.target.name] = e.target.value
      this.setState(new_state)
      var custom_calc_params = _.pick(new_state, 'rpm', 'ipm')
      var param_values = _.map(_.values(custom_calc_params), function(param_value){
          return parseFloat(param_value)
      })
      var params_are_numbers = _.some(param_values, function(param_value){
          return _.isNumber(param_value) && !_.isNaN(param_value)
      })

      if(params_are_numbers){
          $.get(this.props.calculation_path + ".json", custom_calc_params, function(data){
              // console.log(data)

              that.setState(data)
          })
      }
  }
}
