var CutPercentagePreviewer = createReactClass({

  render: function() {
    return <div>
        <div className="cut-preview">
            <div className="cut-preview__tool">
                <img className="cut-preview__model    max-width: 275px;
    max-height: 350px;" ref="cutModel" src={this.props.cut_model} style={this.modelPosition()} onLoad={this.setMaterialMargins} />
            </div>
            <div className="cut-preview__material" style={this.state.materialMargins}> </div>
        </div>
    </div>
  },

  setMaterialMargins: function(e){
      margins = {}
      margins['marginTop'] = "" + e.target.height +"px"
      margins['marginLeft'] = "" + e.target.width + "px"
      this.setState({materialMargins: margins})
  },

  getInitialState: function(){
    var radial_width = parseFloat($("#usage_radial_width").val())      || null
    var axial_depth  = parseFloat($("#usage_total_axial_depth").val()) || null
    var tool_width   = $("#tool").data('cutting-diameter')             || null
    var tool_depth   = $("#tool").data('cutting-depth')                || null
    var material_margins = {}
    material_margins ['marginTop'] = 0
    material_margins ['marginLeft'] = 0

    return {
        radial_width: radial_width,
        axial_depth: axial_depth,
        tool_width: tool_width,
        tool_depth: tool_depth,
        percentage: { width: radial_width / tool_width, depth: axial_depth / tool_depth},
        materialMargins: material_margins,
        previousRadialWidth: radial_width,
    }
  },

   componentDidMount: function(){
     var that = this

     $("#usage_radial_width").change(function(){
       var previousRadialWidth = that.state.previousRadialWidth
       if(!$("#usage_slot").prop('checked')){
         previousRadialWidth = $(this).val()
       }
       that.setState({radial_width: $(this).val(), previousRadialWidth: previousRadialWidth })
       that.updatePercentage()
     })
     $("#usage_total_axial_depth").change(function(){
       that.setState({axial_depth: $(this).val()})
       that.updatePercentage()
     })
     $("#tool_width").change(function(){
       that.setState({tool_width: $(this).val()})
       that.updatePercentage()
     })
     $("input").blur(function(){
         that.setState({show_update_button: false})
     })
     $("#usage_slot").change(function(e){
         if($("#usage_slot").prop('checked')){
             $("#usage_radial_width").val(that.state.tool_width)
             $("#usage_radial_width").trigger('change')
             $("#usage_radial_width").attr('readonly', true)
         }else {
             $("#usage_radial_width").prop('readonly', false)
             $("#usage_radial_width").val(that.state.previousRadialWidth)
             $("#usage_radial_width").trigger('change')
         }
     })

     $("#usage_radial_width, #usage_total_axial_depth").keyup(function(){
         $(this).blur()
         $(this).focus()
     })
     $("#tool select").change(function(e){
         if(e.target.name.indexOf("cutting_depth") > -1 ){
             that.setState({
                 tool_depth: e.target.value
             })
             that.updatePercentage()
         }else if(e.target.name.indexOf('cutting_diameter') > -1 ){
             that.setState({
                 tool_width: e.target.value
             })
             if($("#slot").prop('checked')){
                 $("#usage_radial_width").val(that.state.tool_width)
                 $("#usage_radial_width").trigger('change')
             }else{
                 that.updatePercentage()
             }
         }
     })
   },
   statesDefined: function(stateNames){
       var that = this
       return _.every([stateNames], function(attr){
            return !_.isNaN(that[attr])

       })
   },
   updatePercentage: function(){
       var that = this
       var new_percentage = {}

       if(this.statesDefined('radial_width', 'tool_width')){
           new_percentage['width'] = this.state.radial_width / this.state.tool_width
       }
       if(this.statesDefined('axial_depth', 'tool_depth')){
           new_percentage['depth'] = this.state.axial_depth / this.state.tool_depth
       }
       // console.log(new_percentage)

       if(!_.isEmpty(new_percentage)){
           this.setState({
                percentage: new_percentage
           })
       }
   },
   hideUpdate: function(){
        this.setState({show_update_button: false})
   },
   modelPosition: function(){
       var  left = 0
       var top = 0
       if(!_.isNaN(this.state.percentage.width)){
           left = $(this.refs.cutModel).width() * this.state.percentage.width
       }
       if(!_.isNaN(this.state.percentage.depth)){
           top = $(this.refs.cutModel).height() * (1 - 0.30275229357) * this.state.percentage.depth
       }
       return {
           left: left,
           top: top
       }
   }

})
