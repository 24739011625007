import React from 'react'
import ReactDOM from 'react-dom'
import TestToolReportNumberHelper from 'src/test_tool_report_number_helper'

export default class TestToolCalcSingleInput extends React.Component{
  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).find("input")
                                 .blockInput(TestToolReportNumberHelper.blockInputConfigFor(this.props.valueType));
  }

  handleInputChange(e) {
    this.props.onInputChange(this.props.datakey,
                             TestToolReportNumberHelper.convert(e.target.value, this.props.valueType));
  }

  componentDidMount() {
    if (this.props.helpText) {
      var contentVariant = "";

      if (this.props.helpText.length > 50) {
        contentVariant = "ttr-help-content--wide";
      }

      $(ReactDOM.findDOMNode(this))
        .find(".ttr-calc-row-input-label__help-icon")
        .tooltipster({
          content: '<div class="ttr-help-content '+contentVariant+'"><p>'+this.props.helpText+'</p></div>',
          contentAsHTML: true
        });
    }
  }

  render() {
    var hasHelp = (this.props.helpText !== undefined);

    return  <div className="col-3 ttr-calc-row__input">
              <div className="ttr-calc-row-input-label">
                <label htmlFor={this.props.datakey}>{this.props.label}</label>
                {hasHelp ?
                  (<div className="ttr-calc-row-input-label__help-icon">
                    <svg className="ttr-help-icon" width="16" height="16"/>
                  </div>)
                  :
                  ("")
                }
              </div>
              <input type="text" name={this.props.datakey} id={this.props.datakey} onChange={this.handleInputChange} required
                     defaultValue={this.props.readonlyValue} disabled={this.props.readonlyValue === undefined ? false : true} />
            </div>
  }
}
