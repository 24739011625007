import React from 'react'
import _ from 'lodash'
import PubSub from 'pubsub-js'

class FormSwitcher extends React.Component{
  render() {
      var that = this
      return <div className="toggle-group">
        {
            this.state.sections.map(function(section, idx){
                return <div key={idx} className={" search" + '-' + section.name }>
                    <label className={"toggle__label" + (section.enabled ? " toggle__label--active" : "" )} htmlFor={that.props.toggle_name + '-' + section.name}>{section.name}</label>
                    <input id={that.props.toggle_name + '-' + section.name} type="radio" className="toggle__input" name={that.props.toggle_name} value={section.name} onChange={that.switchSection.bind(that, section)} checked={section.enabled} />
                </div>
            })
        }
    </div>
  }
  switchSection(section, e){
      this.setState({sections: this.enableSection(section, this.state.sections)})
  }
  inputClass(tool_name){
      return 'toggle__label'
          + (this.state.current_tool == tool_name ? ' toggle__label--active' : '')
          + (this.props.disable ? ' toggle__label--disabled' : '')
  }
  formInputs(parentContainer){
    return $(parentContainer).find("input, select").not(function(i, element) {
        $(this).prop('type') == 'hidden' && !$(this).hasClass('required')
    })
  }
  componentDidMount(){
      var that = this
    _.each(this.state.sections, function(section){
        var sectionClass = '.' + section.class
        if(section.enabled == true){
            $(sectionClass).addClass('toggle__section--active')
            that.formInputs(sectionClass).prop("disabled", false)
        }else{
            $(sectionClass).addClass('toggle__section--disabled')
            that.formInputs(sectionClass).prop("disabled", true)
        }
    })
    this.setState({ channel: PubSub.subscribe( 'inputs_mounted', this.inputsMounted.bind(this))});
  }
  componentWillUnmount(){
      PubSub.unsubscribe(this.state.channel);
  }
  inputsMounted(msg, data){
      var that = this
    _.each(this.state.sections, function(section){
        var sectionClass = '.' + section.class
        that.formInputs(sectionClass).prop("disabled", !section.enabled)
    })
  }
  constructor(props){
      super(props);
      this.state =  { sections: this.props.sections }
  }
  componentDidUpdate(){
      var that = this
      _.each(this.state.sections, function(section){
          var sectionClass = '.' + section.class
          if(section.enabled == true){
              $(sectionClass).addClass("toggle__section--active")
              $(sectionClass).removeClass("toggle__section--disabled")
              that.formInputs(sectionClass).prop("disabled", false)
                //TODO: find new way to get visible element
          }else{
              $(sectionClass).addClass("toggle__section--disabled")
              $(sectionClass).removeClass("toggle__section--active")
              that.formInputs(sectionClass).prop("disabled", true)
          }
      })

      //activateNext()
  }
  enableSection(enabled_section, sections){
      return _.map(sections, function(section){
          if(section.name == enabled_section.name){
              enabled_section.enabled = true
              return enabled_section
          }
          section.enabled = false
          return section
      })
  }
  enabledSection(){
      return _.find(this.state.sections, function(section){
          return section.enabled == true
      })
  }
}


export default FormSwitcher