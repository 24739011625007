export default class TestToolReportCalcHelper {
  // Private
  static _isBlank(value) {
    if (value === undefined || value.length == 0)
      return true;

    return false;
  }

  static _anyBlankData() {
    var data = arguments[0];

    for (var i = 1; i < arguments.length; i++) {
      if (this._isBlank(data[arguments[i]]))
        return true;
    }

    return false;
  }

  static _makeObj(k,v) {
    var o = {};
    o[k] = v;
    return o;
  }

  static _key(l,r) {
    return l + "-" + r;
  }

  static _calculations = [
    // "result-surface-speed": function(target, data) {
    function(target, data) {
      // Surface speed - feet / minute (D x .262 x N)
      // D datakey="calc-input-rpm-sgs"
      // N datakey="calc-input-diameter-sgs"

      var dataKey = this._key("result-surface-speed",target),
          dataValue;

      var rpmKey = this._key("calc-input-rpm", target),
          diameterKey = this._key("calc-input-diameter",target);

      if (! this._anyBlankData(data, rpmKey, diameterKey)) {
        dataValue = data[rpmKey] * 0.262 * data[diameterKey];
      }

      return this._makeObj(dataKey, dataValue);
    },

    // "result-feed": function(target, data) {
    function(target, data) {
      // Feed - inches / minute (D x E)
      // D datakey="calc-input-rpm-sgs"
      // E datakey="calc-input-feed-sgs"
      var dataKey = this._key("result-feed",target),
          dataValue;

      var rpmKey = this._key("calc-input-rpm", target),
          feedKey = this._key("calc-input-feed",target);

      if (! this._anyBlankData(data, rpmKey, feedKey)) {
        dataValue = data[rpmKey] * data[feedKey];
      }

      return this._makeObj(dataKey, dataValue);
    },

    // "result-tool-parts-per-tool": function(target, data) {
    function(target, data) {
      // Total parts available per tool (J x K) + I
      // J datakey="calc-input-regrind-parts-produced-sgs"
      // K datakey="calc-input-regrinds-available-sgs"
      // I datakey="calc-input-new-tool-parts-produced-sgs"
      var dataKey = this._key("result-tool-parts-per-tool",target),
          dataValue;

      var regrindPartsKey   = this._key("calc-input-regrind-parts-produced", target),
          regrindsAvailKey  = this._key("calc-input-regrinds-available",target),
          partsProducedKey  = this._key("calc-input-new-tool-parts-produced",target);

      if (! this._anyBlankData(data, regrindPartsKey, regrindsAvailKey, partsProducedKey)) {
        dataValue = (data[regrindPartsKey] * data[regrindsAvailKey]) + data[partsProducedKey];
      }

      return this._makeObj(dataKey, dataValue);
    },

    // "result-num-new-tools-required": function(target, data){
    function(target, data){
      // New tools required to complete job (A / Q)
      // A datakey="calc-input-quantity-sgs"
      // Q datakey-sgs="result-tool-parts-per-tool-sgs"

      var dataKey = this._key("result-num-new-tools-required",target),
          dataValue;

      var quantityKey     = this._key("calc-input-quantity", target),
          partsPerToolKey = this._key("result-tool-parts-per-tool",target);

      if (! this._anyBlankData(data, quantityKey, partsPerToolKey)) {

        dataValue = Math.ceil(data[quantityKey] / data[partsPerToolKey]);

        if (! isFinite(dataValue)) {
          dataValue = 0; // incase we get a divide by zero
        }
      }

      return this._makeObj(dataKey, dataValue);
    },

    // "result-regrinds-required": function(target, data){
    function(target, data){
      // Regrinds required to compete job (A-(I x R) / J
      // A datakey="calc-input-quantity-sgs"
      // I datakey="calc-input-new-tool-parts-produced-sgs"
      // R datakey-sgs="result-num-new-tools-required-sgs"
      // J datakey="calc-input-regrind-parts-produced-sgs"

      var dataKey = this._key("result-regrinds-required",target),
          dataValue;

      var quantityKey             = this._key("calc-input-quantity", target),
          partsProducedKey        = this._key("calc-input-new-tool-parts-produced",target),
          newToolsReqKey          = this._key("result-num-new-tools-required",target),
          regrindPartsProducedKey = this._key("calc-input-regrind-parts-produced",target);

      if (! this._anyBlankData(data, quantityKey, partsProducedKey, newToolsReqKey, regrindPartsProducedKey)) {

        dataValue = (data[quantityKey]-(data[partsProducedKey]*data[partsProducedKey])) / data[regrindPartsProducedKey]

        if (! isFinite(dataValue)) {
          dataValue = 0; // incase we get a divide by zero
        }
      }

      return this._makeObj(dataKey, dataValue);
    },

    // "result-total-hours-machine-time": function(target, data) {
    function(target, data) {
      // Total hours of machining time (C x A) / (P x 60)
      // C datakey="calc-input-milled-drilled-length-sgs"
      // A datakey="calc-input-quantity-sgs"
      // P datakey-sgs="result-feed-sgs"

      var dataKey = this._key("result-total-hours-machine-time",target),
          dataValue;

      var mdLengthKey = this._key("calc-input-milled-drilled-length", target),
          quantityKey = this._key("calc-input-quantity",target),
          feedKey     = this._key("result-feed",target);

      if (! this._anyBlankData(data, mdLengthKey, quantityKey, feedKey)) {

        dataValue = ( (data[mdLengthKey] * data[quantityKey]) / (data[feedKey] * 60) )

        if (! isFinite(dataValue)) {
          dataValue = 0; // incase we get a divide by zero
        }
      }

      return this._makeObj(dataKey, dataValue);
    },

    // "result-total-machining-cost": function(target, data) {
    function(target, data) {
      // Total machining cost (T x B)
      // T datakey="result-total-hours-machine-time-sgs"
      // B datakey="calc-input-hourly-rate-sgs"


      var dataKey = this._key("result-total-machining-cost",target),
          dataValue;

      var mtKey   = this._key("result-total-hours-machine-time", target),
          hrKey   = this._key("calc-input-hourly-rate",target),
          minsKey = this._key("calc-input-cycle-time-mins",target),
          secsKey = this._key("calc-input-cycle-time-secs",target),
          ptpKey  = this._key("calc-input-quantity",target);

      // E9 = =E19/60+E17
              // = seconds / 60+minutes

      if (! this._anyBlankData(data, mtKey, hrKey, minsKey, secsKey, ptpKey)) {
        var cycleTime = (data[secsKey]/60) + data[minsKey];

        if (cycleTime > 0) {
          // (E21/60)*E9*E20
          // (shop rate / 60)*cycleTime*partstoproduce
          dataValue = (data[hrKey]/60)*cycleTime*data[ptpKey];
        } else {
          // E39*E21
          dataValue = data[mtKey] * data[hrKey];
        }

      }

      return this._makeObj(dataKey, dataValue);
    },

    // "result-total-new-tool-cost": function(target, data) {
    function(target, data) {
      // Total new tool cost (F x R)
      // F datakey="calc-input-new-tool-cost-sgs"
      // R datakey-sgs="result-num-new-tools-required-sgs"
      var dataKey = this._key("result-total-new-tool-cost",target),
          dataValue;

      var ntcKey = this._key("calc-input-new-tool-cost", target),
          ntrKey = this._key("result-num-new-tools-required",target);


      if (! this._anyBlankData(data, ntcKey, ntrKey)) {
        dataValue = data[ntcKey] * data[ntrKey];
      }

      return this._makeObj(dataKey, dataValue);
    },

    // "result-tool-change-cost": function(target, data) {
    function(target, data) {
      // Tool change cost (R + S) (G x B / 60)
      // R datakey-sgs="result-num-new-tools-required-sgs"
      // S datakey-sgs="result-regrinds-required-sgs"
      // G datakey="calc-input-tool-change-time-sgs"
      // B datakey="calc-input-hourly-rate-sgs"

      var dataKey = this._key("result-tool-change-cost",target),
          dataValue;

      var ntrKey  = this._key("result-num-new-tools-required", target),
          rrKey   = this._key("result-regrinds-required",target),
          tctKey  = this._key("calc-input-tool-change-time",target),
          hrKey   = this._key("calc-input-hourly-rate",target);

      if (! this._anyBlankData(data, ntrKey, rrKey, tctKey, hrKey)) {
        dataValue = (data[ntrKey] + data[rrKey]) * (data[tctKey] * data[hrKey] / 60);

        if (! isFinite(dataValue)) {
          dataValue = 0; // incase we get a divide by zero
        }
      }

      return this._makeObj(dataKey, dataValue);
    },

    function(target, data) {

      // datakey-sgs="result-regind-cost-sgs"
      // Regrind cost (S x H)
      // S datakey-sgs="result-regrinds-required-sgs"
      // H datakey="calc-input-tool-regrind-cost-sgs"

      var dataKey = this._key("result-regind-cost",target),
          dataValue;

      var rcKey = this._key("calc-input-tool-regrind-cost", target),
          rrKey = this._key("result-regrinds-required",target);

      if (! this._anyBlankData(data, rcKey, rrKey)) {
        dataValue = data[rcKey] * data[rrKey];
      }

      return this._makeObj(dataKey, dataValue);
    },

    function(target, data) {
      // datakey-sgs="result-total-cost-sgs"
      // Total Cost (U + V + W + X)
      // U datakey-sgs="result-total-machining-cost-sgs"
      // V datakey-sgs="result-total-new-tool-cost-sgs"
      // W datakey-sgs="result-tool-change-cost-sgs"
      // X datakey-sgs="result-regind-cost-sgs"

      var dataKey = this._key("result-total-cost",target),
          dataValue;

      var tmcKey  = this._key("result-total-machining-cost", target),
          ntcKey  = this._key("result-total-new-tool-cost",target),
          tccKey  = this._key("result-tool-change-cost",target),
          rcKey   = this._key("result-regind-cost",target);

      if (! this._anyBlankData(data, tmcKey, ntcKey, tccKey, rcKey)) {
        dataValue = data[tmcKey] + data[ntcKey] + data[tccKey] + data[rcKey];
      }

      return this._makeObj(dataKey, dataValue);
    },

    function(target, data) {
      // datakey-sgs="result-cost-per-part-sgs"
      // Cost / Part (Y / A)
      // Y datakey-sgs="result-total-cost-sgs"
      // A datakey="calc-input-quantity-sgs"

      var dataKey = this._key("result-cost-per-part",target),
          dataValue;

      var costKey     = this._key("result-total-cost", target),
          quantityKey = this._key("calc-input-quantity",target);

      if (! this._anyBlankData(data, costKey, quantityKey)) {
        dataValue = data[costKey] / data[quantityKey];

        if (! isFinite(dataValue)) {
          dataValue = 0; // incase we get a divide by zero
        }
      }

      return this._makeObj(dataKey, dataValue);
    },

    // datakey-sgs="result-mmr-milling-sgs"
    function(target, data) {
      // L * M * P
      // L datakey="calc-input-axial-depth-sgs"
      // M datakey="calc-input-radial-width-sgs"
      // P datakey-sgs="result-feed-sgs"

      var dataKey = this._key("result-mmr-milling",target),
          dataValue;

      var adKey   = this._key("calc-input-axial-depth", target),
          rwKey   = this._key("calc-input-radial-width",target),
          feedKey = this._key("result-feed",target);

      if (! this._anyBlankData(data, adKey, rwKey, feedKey)) {
        dataValue = data[adKey] * data[rwKey] * data[feedKey]
      }

      return this._makeObj(dataKey, dataValue);
    },

    // datakey-sgs="result-mmr-diff-milling-sgs"
    function(target, data) {
      // =IF(E46>F46,E46-F46,"0")
      var dataKey = this._key("result-mmr-diff-milling",target),
          dataValue;

      var mmrMillKeySgs   = this._key("result-mmr-milling","sgs"),
          mmrMillKeyOther = this._key("result-mmr-milling","other");

      // take the difference between the target's value and the other's value.
      // Basically both have to exist to get any kind of measurable output

      dataValue = 0;
      if (! this._anyBlankData(data, mmrMillKeySgs, mmrMillKeyOther)) {
        if (target == "sgs") {
          if (data[mmrMillKeySgs] > data[mmrMillKeyOther]) {
            dataValue = data[mmrMillKeySgs] - data[mmrMillKeyOther];
          }
        } else {
          if (data[mmrMillKeyOther] > data[mmrMillKeySgs]) {
            dataValue = data[mmrMillKeyOther] - data[mmrMillKeySgs];
          }
        }
      }

      return this._makeObj(dataKey, dataValue);
    },

    // datakey-sgs="result-mmr-drilling-sgs"
    function(target, data) {
      // =IF(E32>0,0,(3.1416*(E33*E33)/4)*E35)
      // E32 = M
      // E33 = N
      // E35 = P
      // M datakey="calc-input-radial-width-sgs"
      // N datakey="calc-input-diameter-sgs"
      // P datakey-sgs="result-feed-sgs"
      var dataKey = this._key("result-mmr-drilling",target),
          dataValue = 0;

      var rwKey   = this._key("calc-input-radial-width",target),
          diamKey = this._key("calc-input-diameter",target),
          feedKey = this._key("result-feed",target);

      if (! this._anyBlankData(data, rwKey, diamKey, feedKey)) {
        if(data[rwKey] <= 0) {
          dataValue = ((3.1416*(data[diamKey]*data[diamKey])/4)*data[feedKey]);
        }
      }

      return this._makeObj(dataKey, dataValue);
    },

    // datakey-sgs="result-mmr-diff-drilling-sgs"
    function(target, data) {
      // =IF(E48>F48,E48-F48,"0")
      // E48 = result-mmr-drilling
      var dataKey = this._key("result-mmr-diff-drilling",target),
          dataValue = 0;

      var mmrDrillKeySgs   = this._key("result-mmr-drilling","sgs"),
          mmrDrillKeyOther = this._key("result-mmr-drilling","other");

      // take the difference between the target's value and the other's value.
      // Basically both have to exist to get any kind of measurable output

      if (! this._anyBlankData(data, mmrDrillKeySgs, mmrDrillKeyOther)) {
        if (target == "sgs") {
          if (data[mmrDrillKeySgs] > data[mmrDrillKeyOther]) {
            dataValue = data[mmrDrillKeySgs] - data[mmrDrillKeyOther];
          }
        } else {
          if (data[mmrDrillKeyOther] > data[mmrDrillKeySgs]) {
            dataValue = data[mmrDrillKeyOther] - data[mmrDrillKeySgs];
          }
        }
      }

      return this._makeObj(dataKey, dataValue);
    },

    // datakey-sgs="result-cut-time-per-part-sgs"
    function(target, data) {
      // =IF(E9>0,0,E22/E35)
      // E9 = (cycle time) =E19/60+E17
      // E22 = C
      // E35 = P
      // C datakey="calc-input-milled-drilled-length-sgs"
      // P datakey-sgs="result-feed-sgs"

      var dataKey = this._key("result-cut-time-per-part",target),
          dataValue = 0;

      var mdlKey  = this._key("calc-input-milled-drilled-length", target),
          feedKey = this._key("result-feed",target),
          minsKey = this._key("calc-input-cycle-time-mins",target),
          secsKey = this._key("calc-input-cycle-time-secs",target);

      if (! this._anyBlankData(data, mdlKey, feedKey, minsKey, secsKey)) {
        var cycleTime = (data[secsKey]/60) + data[minsKey];

        if (cycleTime <= 0) {
          dataValue = data[mdlKey] / data[feedKey];
        }

        if (! isFinite(dataValue)) {
          dataValue = 0; // incase we get a divide by zero
        }
      }

      return this._makeObj(dataKey, dataValue);
    },

    // datakey-sgs="result-savings-per-part-sgs"
    function(target, data) {
      // =IF(E45<F45,F45-E45,"0")
      //E45 = Z
      // Z datakey-sgs="result-cost-per-part-sgs"

      var dataKey = this._key("result-savings-per-part",target),
          dataValue = 0;

      var cppKeySgs   = this._key("result-cost-per-part","sgs"),
          cppKeyOther = this._key("result-cost-per-part","other");

      if (! this._anyBlankData(data, cppKeySgs, cppKeyOther)) {
        if (target == "sgs") {
          if (data[cppKeySgs] < data[cppKeyOther]) {
            dataValue = data[cppKeyOther] - data[cppKeySgs];
          }
        } else {
          if (data[cppKeyOther] < data[cppKeySgs]) {
            dataValue = data[cppKeySgs] - data[cppKeyOther];
          }
        }
      }

      return this._makeObj(dataKey, dataValue);
    },

    // datakey-sgs="result-total-savings-percent-sgs"
    function(target, data) {
      // =IF(E44<F44,(F44-E44)/F44,"0")
      // E44 = Y
      // Y datakey-sgs="result-total-cost-sgs"
      var dataKey = this._key("result-total-savings-percent",target),
          dataValue = 0;

      var totalCostKeySgs   = this._key("result-total-cost","sgs"),
          totalCostKeyOther = this._key("result-total-cost","other");

      if (! this._anyBlankData(data, totalCostKeySgs, totalCostKeyOther)) {
        if (target == "sgs") {
          if (data[totalCostKeySgs] < data[totalCostKeyOther]) {
            dataValue = ((data[totalCostKeyOther] - data[totalCostKeySgs]) / data[totalCostKeyOther])*100;
          }
        } else {
          if (data[totalCostKeyOther] < data[totalCostKeySgs]) {
            dataValue = ((data[totalCostKeySgs] - data[totalCostKeyOther]) / data[totalCostKeySgs])*100;
          }
        }
      }

      if (! isFinite(dataValue)) {
        dataValue = 0; // incase we get a divide by zero
      }

      return this._makeObj(dataKey, dataValue);
    },

    // datakey-sgs="result-total-savings-currency-sgs"
    function(target, data) {
      // =IF(E44<F44,F44-E44,"0")
      // E44 = Y
      // Y datakey-sgs="result-total-cost-sgs"
      var dataKey = this._key("result-total-savings-currency",target),
          dataValue = 0;

      var totalCostKeySgs   = this._key("result-total-cost","sgs"),
          totalCostKeyOther = this._key("result-total-cost","other");

      if (! this._anyBlankData(data, totalCostKeySgs, totalCostKeyOther)) {
        if (target == "sgs") {
          if (data[totalCostKeySgs] < data[totalCostKeyOther]) {
            dataValue = data[totalCostKeyOther] - data[totalCostKeySgs]
          }
        } else {
          if (data[totalCostKeyOther] < data[totalCostKeySgs]) {
            dataValue = data[totalCostKeySgs] - data[totalCostKeyOther]
          }
        }
      }

      return this._makeObj(dataKey, dataValue);
    }

    // datakey-sgs="result-tool-parts-per-tool-sgs"
    // datakey-sgs="result-num-new-tools-required-sgs"
    // datakey-sgs="result-regrinds-required-sgs"
    // datakey-sgs="result-total-hours-machine-time-sgs"
    // datakey-sgs="result-total-machining-cost-sgs"
    // datakey-sgs="result-total-new-tool-cost-sgs"
    // datakey-sgs="result-tool-change-cost-sgs"
    // datakey-sgs="result-regind-cost-sgs"
    // datakey-sgs="result-total-cost-sgs"
    // datakey-sgs="result-cost-per-part-sgs"

    // datakey-sgs="result-mmr-milling-sgs"
    // datakey-sgs="result-mmr-diff-milling-sgs"
    // datakey-sgs="result-mmr-drilling-sgs"
    // datakey-sgs="result-mmr-diff-drilling-sgs"
    // datakey-sgs="result-cut-time-per-part-sgs"
    // datakey-sgs="result-savings-per-part-sgs"
    // datakey-sgs="result-total-savings-percent-sgs"
    // datakey-sgs="result-total-savings-currency-sgs"

    // Surface speed - feet / minute (D x .262 x N)
    // Feed - inches / minute (D x E)
    // Total parts available per tool (J x K) + I
    // New tools required to complete job (A / Q)
    // Regrinds required to compete job (A-(I x R) / J
    // Total hours of machining time (C x A) / (P x 60)
    // Total machining cost (T x B)
    // Total new tool cost (F x R)
    // Tool change cost (R + S) (G x B / 60)
    // Regrind cost (S x H)
    // Total Cost (U + V + W + X)
    // Cost / Part (Y / A)

    // A datakey="calc-input-quantity-sgs"
    // B datakey="calc-input-hourly-rate-sgs"
    // C datakey="calc-input-milled-drilled-length-sgs"
    // D datakey="calc-input-rpm-sgs"
    // E datakey="calc-input-feed-sgs"
    // F datakey="calc-input-new-tool-cost-sgs"
    // G datakey="calc-input-tool-change-time-sgs"
    // H datakey="calc-input-tool-regrind-cost-sgs"
    // I datakey="calc-input-new-tool-parts-produced-sgs"
    // J datakey="calc-input-regrind-parts-produced-sgs"
    // K datakey="calc-input-regrinds-available-sgs"
    // L datakey="calc-input-axial-depth-sgs"
    // M datakey="calc-input-radial-width-sgs"
    // N datakey="calc-input-diameter-sgs"

    // P datakey-sgs="result-feed-sgs"
    // Q datakey-sgs="result-tool-parts-per-tool-sgs"
    // R datakey-sgs="result-num-new-tools-required-sgs"
    // S datakey-sgs="result-regrinds-required-sgs"
    // T datakey-sgs="result-total-hours-machine-time-sgs"

    // U datakey-sgs="result-total-machining-cost-sgs"
    // V datakey-sgs="result-total-new-tool-cost-sgs"
    // W datakey-sgs="result-tool-change-cost-sgs"
    // X datakey-sgs="result-regind-cost-sgs"
    // Y datakey-sgs="result-total-cost-sgs"
    // Z datakey-sgs="result-cost-per-part-sgs"

    // datakey-sgs="result-mmr-milling-sgs"
    // datakey-sgs="result-mmr-diff-milling-sgs"
    // datakey-sgs="result-mmr-drilling-sgs"
    // datakey-sgs="result-mmr-diff-drilling-sgs"
    // datakey-sgs="result-cut-time-per-part-sgs"
    // datakey-sgs="result-savings-per-part-sgs"
    // datakey-sgs="result-total-savings-percent-sgs"
    // datakey-sgs="result-total-savings-currency-sgs"

  ]

  static _calculate(fn, data) {
    var sgsData   = fn("sgs", data);
    var otherData = fn("other", data);
    return $.extend(sgsData, otherData);
  }

  static doAllCalculations(data) {
    this._calculations.forEach(function(calcFn, idx){
        data = $.extend(data, TestToolReportCalcHelper._calculate(calcFn, data));
      });

      return data;
  }
}
