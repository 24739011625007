import React from "react"
import MaterialBreadcrumbs from 'components/MaterialBreadcrumbs'
import MaterialSelector from 'components/MaterialSelector'

import _ from 'lodash'

class AnalysisMaterialForm extends React.Component {
  render() {
    var that = this
    var parent = this.props.parent
    return <div>
        <form action={this.props.action} method='POST'>
            <input type='hidden' name='authenticity_token' value={this.props.authenticity_token} />
            {
              this.props.selections.map(function(selection, idx){
                return <input 
                        key={idx} 
                        type='hidden' 
                        name={'analysis_material['+ selection.name + '_id]'} 
                        value={selection.value} />
             })
            }

            <div className="tabbed__primary-content">
              <MaterialBreadcrumbs 
                selections={this.props.selections} check_image={this.props.images.check} parent={this} > </MaterialBreadcrumbs>
            <div className="centerSelection">
              <div className="material_selection">
                <div className="material_selection__previous" onClick={parent.viewSelection.bind(null, parent.previousSelection())} dangerouslySetInnerHTML={{__html: this.props.images.left_arrow}} />
                <MaterialSelector selection={parent.currentSelection()} parent={that.props.parent}> </MaterialSelector>
                <div  className="material_selection__next" onClick={parent.viewSelection.bind(null, parent.nextSelection())} dangerouslySetInnerHTML={{__html: this.props.images.right_arrow}} />
              </div>
            </div>

            </div>
            <div className="form__actions">
                { /*<input type='submit' value='Save' className="button button__save" />*/ }
                <input 
                  type='submit' 
                  value='Next' 
                  className={"button button__next " + (this.allSelectionsMeet() ? 'active' : 'disabled') }
                  disabled={!this.allSelectionsMeet()}
                  />
            </div>
        </form>
    </div>
  };
  currentSelection(){
      return this.props.parent.currentSelection()
  };
  viewSelection(selection){
      this.props.parent.viewSelection(selection)
  };
  allSelectionsMeet(){
      return _.every(this.props.selections, function(selection){
          return _.isNumber(selection.value) && !_.isNaN(selection.value)
      })
  };

  constructor(props){
    super(props)
  };

}

export default AnalysisMaterialForm;