import React from 'react'
import ReactDOM from 'react-dom'

export default class  TestToolExtraInfo extends React.Component{
  componentDidUpdate() {
    // Update the chart.
    var chart = this.state.chart;

    if (this.props.data["calc-input-vendor-other"] !== undefined) {
      var chartLabels = chart.data.labels;
      chartLabels[1] = this.props.data["calc-input-vendor-other"];
      chart.labels = chartLabels;
    }

    if (this.props.data["result-cost-per-part-sgs"] !== undefined) {
      chart.data.datasets[0].data[0] = this.props.data["result-cost-per-part-sgs"].toFixed(2);
    }

    if (this.props.data["result-cost-per-part-other"] !== undefined) {
      chart.data.datasets[0].data[1] = this.props.data["result-cost-per-part-other"].toFixed(2);
    }

    chart.update();
  }

  constructor(props) {
    super(props)
    this.state = {};
  }

  handleNoteChange(e) {
    this.props.onNoteInputChange("notes",e.target.value);
  }

  handleExportClick(e) {
    this.props.onExport($(e.target).closest("form"), this.state.chart);
  }

  componentDidMount() {
    // Set up the graph
    var ctx = $(ReactDOM.findDOMNode(this)).find("#cost-per-part-graph");

    // Probably not the *best* idea here, but it works for now. Need to keep
    // a reference to the chart so we can update it later when an input is updated
    // by the user.
    this.state.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ["SGS", "Unknown"],
        datasets: [{
          label: 'Cost Per Part',
          data: [0, 0],
          backgroundColor: [
            'rgba(54, 162, 235, 0.4)',
            'rgba(189, 198, 207, 0.4)',
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(137,153,169,1)',
          ],
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        title: {
          display: true,
          text: 'Cost Per Part',
          padding: 15
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero:true,
              callback: function(value, index, values) {
                return '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');;
              }
            }
          }]
        }
      }
    });
  }

  render() {
    return  <div className="row ttr-extras">
              <div className="col-6">
                <canvas id="cost-per-part-graph" width="300" height="300"></canvas>
              </div>
              <div className="col-6 ttr-extras__notes">
                <textarea rows="4" placeholder="Notes" onBlur={this.handleNoteChange.bind(this)}></textarea>
                <form id="test-tool-report-export" action="/sales/test_tool_report" acceptCharset="UTF-8" method="post">
                  <input name="utf8" type="hidden" value="✓" />
                  <input type="hidden" name="authenticity_token" value={this.props.auth_token} />
                  <button id="export-button" className="button button__next active" onClick={this.handleExportClick.bind(this)}>Export Data</button>
                </form>
              </div>
            </div>
  }
}
