import React from 'react'
import ReactDOM from 'react-dom'
import TestToolReportNumberHelper from 'src/test_tool_report_number_helper'

export default class TestToolCalcCycleTimeInput extends React.Component{
  componentDidMount() {
    // Cycle Time Input is only for integers
    $(ReactDOM.findDOMNode(this))
              .find("input")
              .blockInput(TestToolReportNumberHelper.blockInputConfigFor("int"));
  }

  handleMinsInputChange(e) {
    this.props.onInputChange(this.props["mins-datakey"],
                             TestToolReportNumberHelper.convert(e.target.value, "int"));
  }

  handleSecsInputChange(e) {
    this.props.onInputChange(this.props["secs-datakey"],
                             TestToolReportNumberHelper.convert(e.target.value, "int"));
  }

  render() {
    return  <div className="col-3 ttr-calc-row__input">
              <div className="ttr-cycle-time-minutes">
                <label htmlFor={this.props["mins-datakey"]}>Minutes</label>
                <input type="text" name={this.props.mins_datakey} id={this.props["mins-datakey"]} onChange={this.handleMinsInputChange} required />
              </div>
              <div className="ttr-cycle-time-seconds">
                <label htmlFor={this.props["secs-datakey"]}>Seconds</label>
                <input type="text" name={this.props["secs-datakey"]} id={this.props["secs-datakey"]} onChange={this.handleSecsInputChange} required />
              </div>
            </div>
  }
}