import React from "react"

class MaterialBreadcrumbs extends React.Component{
  render() {
    return <div className={ "material_breadcrumbs" + (this.state.mobileMenuOpen ? " material_breadcrumbs--active" : "" ) }>
      {
        this.props.selections.map( function(selection, idx){
          return <div key={idx} style={this.styles()} className={selection.current ? "material_breadcrumb material_breadcrumb--current" : "material_breadcrumb"} onClick={() => this.toggleMobileMenu(selection)} >
            <div className="material_breadcrumb__status" dangerouslySetInnerHTML={{__html: selection.value ? this.props.check_image : ""}}  />

            <span className={selection.current ? "sixth-headline txt-blue" : "sixth-headline"}>{ selection.heading }</span>
          </div>
        }, this)
      }
    </div>
  };
  constructor(props){
    super(props);
    this.state =  { mobileMenuOpen : false };

    this.toggleMobileMenu =  this.toggleMobileMenu.bind(this);
    this.styles = this.styles.bind(this);

  };
  toggleMobileMenu(selection){
      if(this.props.parent.currentSelection() !=  selection){
          this.props.parent.viewSelection(selection)
      }
      this.setState({mobileMenuOpen  : !this.state.mobileMenuOpen })
  };
  styles(){
    return {width: (100.0 / this.props.selections.length).toString() + "%"}
  };

}

export default  MaterialBreadcrumbs;