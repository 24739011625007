import React from 'react'
import _ from 'lodash'

class ToolSearchResults extends React.Component{
  sortArrow(column){
      if( column != this.props.sort_by){ return ""}
      //return this.props.sort_direction == 'asc' ? String.fromCharCode(65514) : String.fromCharCode(65516)
      return this.props.sort_direction == 'asc' ? String.fromCharCode(8593) : String.fromCharCode(8595)
  }
  render() {
      var that = this
      var starting_page =  this.props.current_page - 5 < 1 ? 1 : this.props.current_page - 5
      var ending_page = _.min([starting_page + 10, this.props.num_pages])
      var has_point_angle = _.some(this.props.tools, function(tool){return tool.point_angle > 0;})
      return <div>
        <input type="hidden" className="required" name="id" value={this.state.selected_tool.edp} />
        <div className="table-wrapper scrollable">
            <table className="table table--striped summary__table">
              <thead>
                <tr className="summary__table__heading">
                  {
                   this.state.columns.map(function(column, idx){
                       if (!has_point_angle && column.attr == "point_angle") { return(null); }
                       return <td key={idx} className={column.sortable ? "tool__sortable" : ""} onClick={that.sortBy.bind(that, column.attr)}> {column.title} {that.sortArrow(column.attr)}</td>
                   })
                  }
                </tr>
              </thead>
              <tbody>
                {
                    this.props.tools.map(function(tool, idx){
                        return <tr key={idx} onClick={that.selectTool.bind(that, tool)} className={  "filter__result" + (that.state.selected_tool.id == tool.id ? " filter__tool--selected" : "")}>

                            {
                             that.state.columns.map(function(column, idx){
                                 if (!has_point_angle && column.attr == "point_angle") { return(null); }
                                 return <td key={idx}> {tool[column.attr]} </td>
                             })
                            }
                        </tr>
                    })
                }
              </tbody>
            </table>
        </div>
        <div className="pagination">
            <ul>
            {
                _.map(_.range(starting_page, ending_page + 1), function(page, idx){
                                                                                                                return <li key={idx} className={ 'page ' + (page == that.props.current_page ? "page--current" : "")} onClick={that.updatePage.bind(that, page)}> {page} </li>
                })
            }
            </ul>
        </div>
    </div>
  }
  componentDidMount(){
      PubSub.publish( 'inputs_mounted', true);
  }
  componentDidUpdate(){
      activateNext()
  }
  updatePage(page){
      this.props.parent.updatePage(page)
  }
  sortBy(column){
      this.props.parent.sortBy(column)
  }
  constructor(props){
      super(props);
      this.state = {
          selected_tool: {id: null},
          columns: [
              {title: "EDP", attr: "edp"},
              {title: "Description", attr: "description", sortable: true},
              {title: <span> D<sub>1</sub><br/>Cutting Diameter</span>, attr: "cutting_diameter_display", sortable: true},
              {title: <span> D<sub>2</sub><br/>Shank Diameter</span>, attr: "shank_diameter_display", sortable: true},
              {title: <span> L<sub>1</sub><br/>Overall Length</span>, attr: "oal_display", sortable: true},
              {title: <span> L<sub>2</sub><br/>Length Of Cut</span>, attr: "length_cut_display", sortable: true},
              {title: "Series", attr: "series"},
              {title: "Brand", attr: "brand"},
              {title: <span>Helix<br />Angle</span>, attr: "helix_angle", sortable: true},
              {title: <span>Point<br />Angle</span>, attr: "point_angle", sortable: true},
              //{title: <span> Tolerance D<sub>1</sub></span>, attr: "tolerance_diameter_1"},
              //{title: <span> Tolerance D<sub>2</sub></span>, attr: "tolerance_diameter_2"},
          ]
      }
  }
  selectTool(tool, e){
      this.setState({selected_tool: tool})

  }
}

export default ToolSearchResults