import React from 'react'
import _ from 'lodash'

class  EndMillToolCutOptions extends React.Component{
  render() {
    return  (
        <React.Fragment>
        <div className="unit-selector mts mbm">
            <input type="radio" id="imperial" name="usage[units]" value="imperial" checked={this.state.units =='imperial'} onChange={this.updateUnits.bind(this)} disabled={this.props.disable}/>
            <label htmlFor="imperial">Inch</label>
            <input type="radio" id="metric" name="usage[units]" value="metric" checked={this.state.units == 'metric'} onChange={this.updateUnits.bind(this)} disabled={this.props.disable}/>
            <label htmlFor="metric">Metric</label>
            { this.props.disable &&
                <input type="hidden" name="usage[units]" value={this.state.units} />
            }
        </div>

        <div className="row">
            <div className="col-8">
                <div id="tool" 
                    className="l-flex mbn" 
                    data-cutting-diameter={this.state.cutting_diameter} 
                    data-end-configuration={this.state.end_configuration} >

                    { this.state.loading_diameters == false && this.state.end_configuration != '' && this.state.cutting_diameters.length == 0 &&
                    <h2 className="secondary-headline mbs txt-red">Notice: There are no {this.state.tool_type} tools available in {this.state.units} sizes</h2>
                    }
                
                    <div className="tool_attribute l-flex__half-col field mbm">
                        <input type="hidden" name="usage[tool_type]" value="endmill" />
                        <h2 className="sixth-headline mbs">End Configuration</h2>
                        <div className="select">
                            <select onChange={this.updateEndConfig.bind(this)} 
                                    disabled={this.props.disable}
                                    name="usage[end_configuration]" 
                                    value={this.state.end_configuration} >

                                <option value="" disabled>End Configuration</option>
                                {
                                    this.state.end_configs.map(function(config){
                                        return <option key={"config_" + config.value} value={config.value}>{config.label}</option>
                                    }, this)
                                }
                            </select>
                        </div>
                        { this.props.disable && 
                            <input type="hidden" name="usage[tool_type]" value={this.state.tool_type} /> }
                    </div>

                    <div className="tool_attribute l-flex__half-col field mbm">
                        <h2 className="sixth-headline mbs">Cutting Diameter</h2>
                        <div className="select">
                            <select id="tool_width" 
                                    disabled={this.props.disable} 
                                    onChange={this.updateCuttingDiameter.bind(this)} 
                                    name="usage[cutting_diameter]" 
                                    value={this.state.cutting_diameter} 
                                    >
                                <option value="" disabled>Cutting Diameter</option>
                                {
                                    this.state.cutting_diameters.map(function(diameter){
                                        return <option key={"diameter_" + diameter} value={diameter}>{this.formatValue(diameter)}</option>
                                    }, this)
                                }
                            </select>
                        </div>
                        { this.props.disable && 
                            <input type="hidden" name="usage[cutting_diameter]" value={this.state.cutting_diameter} /> }
                    </div>
                </div>

                <div className="l-flex">
                    <div className="tool_attribute l-flex__half-col field mbm">
                        <h2 className="sixth-headline mbs">Radial Width</h2>
                        <input  name="usage[radial_width]" 
                                disabled={!this.state.cutting_diameter}
                                readOnly={this.state.slot}
                                value={this.state.radial_width}
                                pattern="^\d*\.?\d*$"
                                onChange={this.updateRadialWidth.bind(this)} />

                            <div className="usage__slot-cut input-checkbox custom-checkbox">
                                <input name="usage[slot]"
                                        type="checkbox"
                                        disabled={!this.state.cutting_diameter} 
                                        id="usage_slot" 
                                        value={this.state.slot}
                                        onChange={this.updateSlot.bind(this)} 
                                        checked={this.state.slot} />
                                <label htmlFor="usage_slot" className="checkbox-label">Slot Cut?</label>
                            </div>
                    </div>

                    <div className="tool_attribute l-flex__half-col field mbm">
                        <h2 className="sixth-headline mbs">Total Axial Depth</h2>
                        <input  name="usage[total_axial_depth]" 
                                disabled={!this.state.cutting_diameter} 
                                readOnly={this.state.fixed_axial}
                                value={this.state.total_axial_depth}
                                pattern="^\d*\.?\d*$"
                                onChange={this.updateTotalAxialDepth.bind(this)} />
                    </div>                    

                    <div className="tool_attribute l-flex__half-col field mbm">
                        <h2 className="sixth-headline mbs">Maximum RPM</h2>
                        <input name="usage[max_rpm]" 
                                disabled={!this.state.cutting_diameter}
                                value={this.state.max_rpm}
                                pattern="^\d*\.?\d*$"
                                onChange={this.updateMaxRPM.bind(this)} />
                    </div>
                </div>
            </div>
            <div className="col-4 l-shrink-margin--left">
                    <img id="usage_endmills_image" src={this.state.image_src} title={this.state.image_title} />
            </div>
       
        </div> 
        </React.Fragment> 
    )
  }

  constructor(props){
      super(props);
    this.state = {
        units:              this.props.units || 'imperial',
        end_configuration:  this.props.end_configuration || '',
        cutting_diameter:   this.props.cutting_diameter || '',
        radial_width:       this.props.radial_width || '',
        total_axial_depth:  this.props.total_axial_depth || '',
        max_rpm:            this.props.max_rpm || '30000',
        slot:               this.props.slot || false,
        image_src:          this.props.slot ? '/images/slotting.jpg' : '/images/hsm.jpg',
        image_title:        this.props.slot ? 'Slotting' : 'High Speed Machining',
        loading_diameters:  false,
        cutting_diameters: [],
        tool_type: 'endmill', 
        fixed_axial: (this.props.end_configuration && this.props.end_configuration == 'picatinny dovetail'),
        end_configs: [
          {label: 'Ball Nose', value: 'ball nose'},
          {label: 'Chip Breaker (CR)', value: 'chip breaker (cr)'},
          {label: 'Chip Breaker (Square)', value: 'chip breaker (square)'},
          {label: 'Corner Radius', value: 'corner radius'},
          {label: 'Square End', value: 'square end'},
          {label: 'Tapered (Ball)', value: 'tapered (ball)'},
          {label: 'Tapered (Square)', value: 'tapered (square)'},
          {label: 'Picatinny Recoil Groove Tool', value: 'picatinny recoil'},
          {label: 'Picatinny Dovetail Form Tool', value: 'picatinny dovetail'},

        ],
    }
  }
  updateCuttingDiameter(e){
      this.setState({
            cutting_diameter: e.target.value,
            slot:  false,
            radial_width:  '',
            total_axial_depth: this.state.fixed_axial ? '.4100' : ''
      });
    }
  
  updateEndConfig(e){
    this.setState({
            end_configuration: e.target.value,
            cutting_diameter: '',
            slot: false,
            radial_width: '',
            total_axial_depth: '',
            fixed_axial: e.target.value == 'picatinny dovetail' ? true : false,
        });
  }

  updateUnits(e) {
    this.setState({
            units: e.target.value,
            cutting_diameter: '',
            radial_width: '',
            slot: false,
        });
  }

  setDiameterList(){
      this.setState({ loading_diameters: true});
    fetch("/api/v1/tools/cutting_diameters.json?type=endmills")
    .then(response => response.json())
    .then(
        (data) => {
            var jsx = this;
            this.setState({
                cutting_diameters: _.sortBy(data.filter(function(option) {
                    return  option.units == jsx.state.units &&
                            option.end_configuration.toLowerCase() ==
                                jsx.state.end_configuration.toLowerCase();
                    }).map(function(option){ return option.cutting_diameter })),
                loading_diameters: false,
                }
            );
            if (this.state.cutting_diameters.length == 1){
                this.setState({
                    cutting_diameter: this.state.cutting_diameters[0],
                    total_axial_depth: this.state.fixed_axial ? '.4100' : ''
                })
            }
        }
    )
  }

  updateTotalAxialDepth(e){
    this.setState({total_axial_depth: e.target.value});
  }

  updateMaxRPM(e){
    this.setState({max_rpm: e.target.value});
  }
  
  updateSlot(e){
    const slot = e.target.checked;
    this.setState({slot: slot});
    if (slot){
        this.setState({radial_width: this.formatValue(this.state.cutting_diameter)});
    }
  }

  updateImage(slot){
    this.setState({    
        image_src:   slot ? '/images/slotting.jpg' : '/images/hsm.jpg',
        image_title: slot ? 'Slotting' : 'High Speed Machining',
    });
  }

  updateRadialWidth(e){
    if (Number(e.target.value) >= Number(this.state.cutting_diameter) ){
        this.setState({radial_width: this.formatValue(this.state.cutting_diameter), slot: true});
    }else{
        this.setState({radial_width: e.target.value})
    }
  }

  formatValue(value) {
    return this.state.units == 'metric' ? parseFloat(value).toFixed(2) : value;
  }

  componentDidMount(){
      if (this.props.end_configuration) {
          this.setDiameterList();
      }
      this.activateNext();
  }
  
  componentDidUpdate(prevProps, prevState){
        if(this.state.units != prevState.units ||
            this.state.end_configuration != prevState.end_configuration){
            this.setDiameterList();
        }

        if(this.state.slot != prevState.slot || 
            this.state.radial_width != prevState.radial_width ){
            this.updateImage(this.state.slot || 
                    (Number(this.state.radial_width) >= Number(this.state.cutting_diameter)));
        }

    this.activateNext();
  }

  activateNext(){
    var next_button = ".button__next";

    if (this.state.end_configuration &&
        this.state.cutting_diameter &&
        this.state.radial_width &&
        this.state.total_axial_depth &&
        this.state.max_rpm) {
            $(next_button).addClass('active')
            $(next_button).removeClass('disabled')
        }else{
            $(next_button).removeClass('active')
            $(next_button).addClass('disabled')
        }
  }

}

export default EndMillToolCutOptions;