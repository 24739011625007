var SlotCutCheckbox = createReactClass({

  render: function() {
    return <div className="usage__slot-cut input-checkbox custom-checkbox">
        <input type="checkbox" name="usage[slot]" id="usage_slot" value="true" onChange={this.updateCheckbox}checked={this.state.checked} />
        <label htmlFor="usage_slot" className="checkbox-label">Slot Cut?</label>
    </div>
  },

  getInitialState: function(){
    var radial_width = parseFloat($("#usage_radial_width").val())      || null
    var axial_depth  = parseFloat($("#usage_total_axial_depth").val()) || null
    var tool_width   = $("#tool").data('cutting-diameter')             || null

    return {
        checked: this.props.checked,
        radial_width: radial_width,
        axial_depth: axial_depth,
        tool_width: tool_width,
        previousRadialWidth: radial_width,
    }
  },

   componentDidMount: function(){
     var that = this

     $("#usage_radial_width").change(function(){
       var previousRadialWidth = that.state.previousRadialWidth
       if(!$("#usage_slot").prop('checked')){
         previousRadialWidth = $(this).val()
       }
       that.setState({radial_width: $(this).val(), previousRadialWidth: previousRadialWidth })
     })

     $("#tool_width").change(function(){
       that.setState({tool_width: $(this).val()})
       $("#usage_slot").change()
     })

     $("#usage_slot").change(function(e){
      if($("#usage_slot").prop('checked')){
          $("#usage_radial_width").val(that.formatValue(that.state.tool_width))
          $("#usage_radial_width").trigger('change')
          $("#usage_radial_width").attr('readonly', true)
          $("#tool_width").addClass('required');
          $("#usage_endmills_image").attr('src', '/images/slotting.jpg')
      }else {
          $("#usage_radial_width").prop('readonly', false)
          $("#usage_radial_width").val(that.formatValue(that.state.previousRadialWidth))
          $("#usage_radial_width").trigger('change')
          $("#tool_width").removeClass('required');
          $("#usage_endmills_image").attr('src', '/images/hsm.jpg')
      }
      activateNext()
     })

     $("#tool select").change(function(e){
         if(e.target.name.indexOf('cutting_diameter') > -1 ){
             that.setState({
                 tool_width: e.target.value
             })
             if($("#slot").prop('checked')){
                 $("#usage_radial_width").val(that.state.tool_width)
                 $("#usage_radial_width").trigger('change')
             }
         }
     })

     PubSub.subscribe('units_changed', this.unitsChanged)
  },
  updateCheckbox: function(e){
    this.setState({checked: e.target.checked});
  },
  unitsChanged: function(msg, units) {
    this.setState({tool_width: '', previousRadialWidth: ''});
  },
  formatValue: function(value) {
    if(value == '') {
      return value;
    }
    var metric = $("#metric").prop('checked');
    return metric ? parseFloat(value).toFixed(2) : value;
  }
})
