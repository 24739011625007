import React from 'react'

class ToolFilters extends React.Component{
  render() {
      var that = this
      return <div className="filters">
        <div className={this.filterClass()}>
            <div className="pick-filter__actions">
                <a className="pick-filter__action close-filters" onClick={this.toggleFilter}></a>
                <a className="pick-filter__action clear-filters" onClick={this.clearAll.bind(this)}>Clear All</a>
            </div>
            <ul className="filters__list">
            {
                this.props.filters.map(function(filter, idx){
                    return <li key={idx}><span className={"button pick-filters__button" + (!!filter.value ? " filter--active" : "")} onClick={that.props.parent.chooseFilter.bind(null, filter)}> {filter.heading}</span></li>
                })
            }
            </ul>
        </div>
        <div className="filter-foo">
        <span className="filters__label sixth-headline" >Filters:</span>
        <ul className="filters__current">
        {
            _.map(this.currentFilters(), function(filter, idx){
                return <li key={idx}> <div className="button__filter"> <span className="filter__name">{filter.heading}</span><span className="filter__remove" onClick={that.removeFilter.bind(null, filter)}></span></div></li>
            })
        }
        </ul>
        <span className="button button__filter button__filter--add" onClick={this.toggleFilter}></span>
        </div>
    </div>
  }
  constructor(props){
      super(props)
      this.state = { show_filters: false }

      this.toggleFilter = this.toggleFilter.bind(this)
  }
  currentFilters(){
      return _.filter(this.props.filters, function(filter){
          return !!filter.value
      })
  }
  filterClass(){
      return 'pick-filters ' + (this.state.show_filters == true ? "pick-filters--active" : "")
  }
  toggleFilter(){
      this.setState({show_filters  : !this.state.show_filters  })
  }
  clearAll(){
      this.props.parent.clearAll()
  }
  removeFilter(filter){
      this.props.parent.removeFilter(filter)
  }

}

export default ToolFilters
