import React from 'react'

export default class EdpSearchPopup extends React.Component{
  handleSearchInput(e) {
    this.setState({"searchFor": e.target.value});
  }

  handleSearchClick() {
    $.ajax({
      url: "/sales/test_tool_report/edp_search.json",
      data: "q="+this.state.searchFor,
      dataType: 'json',
      cache: false,
      success: function(data) {
        this.setState({searchResults: data});
        $(".ttr-edp-search-results").slideDown(50);
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this)
    });
  }

  handleSelectionPicked(e) {
    this.props.onInputChanged("sgs-product-input", e.currentTarget.textContent);
    this.props.onInputChanged("sgs-product-edp", $(e.currentTarget).data('edp'));
    $(this.props.tooltipRef.context).find('.floating-label-input__label').addClass("floating-label-input__label--float");
    this.props.tooltipRef.val(e.currentTarget.textContent);
    this.props.tooltipRef.tooltipster('close');
  }

  handlePopupClose(e) {
    this.props.tooltipRef.tooltipster('close');
  }

  constructor(props) {
    super(props)
    this.state = {  "searchFor": "",  "searchResults": [] }
  }

  render() {
    return  <div className="ttr-edp-search">
              <a className="ttr-edp-search__close" onClick={this.handlePopupClose.bind(this)}>X</a>
              <label htmlFor={"edp-search"}>Search by EDP</label>
              <div className="ttr-edp-search__input">
                <input id="edp-search" type="text" onChange={this.handleSearchInput.bind(this)} />
                <a id="edp-search-btn" onClick={this.handleSearchClick.bind(this)}>&nbsp;</a>
              </div>
              <div className="ttr-edp-search-results">
                <h6>Results:</h6>
                <ul>
                {this.state.searchResults.map(res =>
                  (res === undefined) ?
                  (<li>none</li>) :
                  (<li onClick={this.handleSelectionPicked.bind(this)} data-edp={res[0]} key={res}>{res[1]}</li>)
                )}
                </ul>
              </div>
            </div>
  }
}