import React from 'react'
import TestToolInfoSection from 'components/test_tool_report/TestToolInfoSection'
import TestToolCalcSection from 'components/test_tool_report/TestToolCalcSection'
import TestToolResultsSection from 'components/test_tool_report/TestToolResultsSection'
import TestToolExtraInfo from 'components/test_tool_report/TestToolExtraInfo'
import TestToolReportCalcHelper from 'src/test_tool_report_calc_helper'


// A test component
class FillTestDataLink extends React.Component{
  handleClick(e) {
    e.preventDefault();
    this.props.onFillTestDataLinkClicked();
  }

  render() {
    return <a href="#" onClick={this.handleClick.bind(this)} className="l-right"> Fill Test Values</a>
  }
}

// The Test Tool Report
// This component houses the main state and is the only place state is maintained / updated.
export default class TestToolReport extends React.Component{
  handleInputChange(key, value) {
    var newInfo = {};
    newInfo[key] = value;

    this.setState(newInfo, function() {
      this.performCalculations(); // Calculate everything as a callback.
    });
  }

  // This is just for development / testing.
  handleDataLinkClicked() {
    values = TestToolReportTestValuesHelper.generate();

    // Fill in the values on the webpage, since these are uncontrolled inputs.
    Object.keys(values).forEach(function(key, idx){
      $('#' + key).val(values[key]);
    });

    $('.floating-label-input__label').addClass("floating-label-input__label--float");

    this.setState(values, function() {
      this.performCalculations(); // Calculate everything as a callback.
    });
  }

  handleExport(formRef, chartRef) {
    var chartImage = {};
    chartImage["chart-image"] = chartRef.toBase64Image() ;
    var exportState = $.extend(this.state, chartImage);
    formRef.append($("<input>").attr("type", "hidden").attr("name", "ttr-data").val(JSON.stringify(exportState)));
  }

  constructor(props) {
    super(props)
    this.state = {
      "calc-input-vendor-sgs": "SGS"
    };
  }

  render() {
    var testFillDataLink = null;

    if (this.props.show_test_values_link == true) {
      testFillDataLink =  <FillTestDataLink onFillTestDataLinkClicked={this.handleDataLinkClicked.bind(this)} />;
    }

    return  <div>
              {testFillDataLink}
              <TestToolInfoSection data={this.state} onInfoInputChange={this.handleInputChange.bind(this)} />
              <TestToolCalcSection data={this.state} onCalcInputChange={this.handleInputChange.bind(this)} />
              <hr className="ttr-split-hr" />
              <TestToolResultsSection data={this.state} onCalcInputChange={this.handleInputChange.bind(this)} />
              <TestToolExtraInfo data={this.state} onNoteInputChange={this.handleInputChange.bind(this)} onExport={this.handleExport.bind(this)} auth_token={this.props.authenticity_token}/>
            </div>
  }

  performCalculations() {
    newValues = TestToolReportCalcHelper.doAllCalculations($.extend({}, this.state));

    this.setState(newValues);
  }
}
