$(document).ready(function(){
  $('.floating-label-input__label').click(function(e){
    e.preventDefault();
    e.stopPropagation();
    var nextElement = $(this).next("input");

    if (nextElement.length > 0) {
      nextElement.trigger("focus");
      return;
    }

    // Could add other element types here.  Note that
    // <select> elements are seemingly impossible to
    // programmatically open.
  });

  var inputFields = $('.floating-label-input__label').next();

  inputFields.each(function(){
    var singleInput = $(this);
    //check if user is filling one of the form fields
    checkVal(singleInput);
    singleInput.on('change keyup', function(){
      checkVal(singleInput);
    });
  });

  function checkVal(inputField) {
    var tagname = inputField.prop("tagName");
    var removeFloatingLabel = false;

    switch(tagname) {
      case "INPUT":
        removeFloatingLabel = (inputField.val() == '' ) ? true : false
        break;
      case "SELECT":
        removeFloatingLabel = (inputField.find("option:selected").text() === "" ? true : false)
        break;
      default:
        return;
    }

    removeFloatingLabel ? inputField.prev('.floating-label-input__label').removeClass('floating-label-input__label--float') :
                          inputField.prev('.floating-label-input__label').addClass('floating-label-input__label--float');
  }
});
