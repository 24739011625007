import  React from 'react'

import EndMillToolCutOptions from 'components/EndMillToolCutOptions'
import DrillToolCutOptions from 'components/DrillToolCutOptions'

function Endmill(props){
    var tool = props.analysis_tool;
    var usage = props.analysis_usage;
    return <EndMillToolCutOptions 
        units = {usage && usage.units}
        end_configuration = {tool && tool.end_configuration || usage && usage.end_configuration}
        cutting_diameter = {tool && tool.cutting_diameter || usage && usage.cutting_diameter}
        radial_width = {usage && usage.radial_width}
        total_axial_depth = {usage && usage.total_axial_depth}
        max_rpm = {usage && usage.max_rpm}
        tool_type = {usage && usage.tool_type}
        slot = {usage && usage.slot}
        disable = {props.disable}
      />
   
}

function Drill(props){
  var tool = props.analysis_tool;
  var usage = props.analysis_usage;
  return <DrillToolCutOptions 
      units = {usage && usage.units}
      cutting_diameter = {tool && tool.cutting_diameter || usage && usage.cutting_diameter}
      radial_width = {usage && usage.radial_width}
      total_axial_depth = {usage && usage.total_axial_depth}
      max_rpm = {usage && usage.max_rpm}
      tool_type = {usage && usage.tool_type}
      disable = {props.disable}
    />
 
}

class UsageForm extends React.Component{
  render() {
    return (
      <div>
        <div className="toggle-group">
          <input type="radio" className="toggle__input" id="toggle_endmill" value="endmill" onChange={this.switchForm.bind(this)} checked={this.checked('endmill')} disabled={this.props.analysis_usage} />
          <label htmlFor="toggle_endmill" className={this.inputClass('endmill')}>Endmills</label>
          <input className="toggle__input" type="radio" id="toggle_drill" value="drill" onChange={this.switchForm.bind(this)} checked={this.checked('drill')} disabled={this.props.analysis_usage} />
          <label htmlFor="toggle_drill" className={this.inputClass('drill')}>Drills</label>
        </div>
  
      { this.state.current_tool == 'endmill' ? Endmill(this.props) :  Drill(this.props) }

    </div>
    )}


  switchForm(e){
    this.setState({current_tool: e.target.value})
  }

  inputClass(tool_name){
      return 'toggle__label'
          + (this.state.current_tool == tool_name ? ' toggle__label--active' : '')
          + (this.props.disable ? ' toggle__label--disabled' : '')
  }

  constructor(props){
    super(props);
     this.state={
          current_tool: (!this.props.analysis_usage && 'endmill' ) || 
                         (this.props.analysis_usage.tool_type != 'endmill' ? 'drill' : 'endmill'),
        }
  }

  checked(toolType){
      return toolType == this.state.current_tool ? true : false
  }
}

export default UsageForm;