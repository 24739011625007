import React from 'react'
import _ from 'lodash'

class DrillToolCutOptions extends React.Component{
    render() {
      return  (
          <React.Fragment>
          <div className="unit-selector mts mbm">
              <input type="radio" id="imperial" name="usage[units]" value="imperial" checked={this.state.units =='imperial'} onChange={this.updateUnits.bind(this)} disabled={this.props.disable}/>
              <label htmlFor="imperial">Inch</label>
              <input type="radio" id="metric" name="usage[units]" value="metric" checked={this.state.units == 'metric'} onChange={this.updateUnits.bind(this)} disabled={this.props.disable}/>
              <label htmlFor="metric">Metric</label>
              { this.props.disable &&
                  <input type="hidden" name="usage[units]" value={this.state.units} />
              }
          </div>
  
          <div className="row">
              <div className="col-8">
                  <div id="tool" 
                      className="l-flex mbn" 
                      data-cutting-diameter={this.state.cutting_diameter} >
  
                      { this.state.loading_diameters == false && this.state.tool_type != '' && this.state.cutting_diameters.length == 0 &&
                      <h2 className="secondary-headline mbs txt-red">Notice: There are no {this.state.tool_type} tools available in {this.state.units} sizes</h2>
                      }
                  
                      <div className="tool_attribute l-flex__half-col field mbm">
                          <h2 className="sixth-headline mbs">Tool Type</h2>
                          <div className="select">
                              <select onChange={this.updateToolType.bind(this)} 
                                      disabled={this.props.disable}
                                      name="usage[tool_type]" 
                                      value={this.state.tool_type} >
  
                                  <option value="" disabled>Tool Type</option>
                                  {
                                      this.state.drill_types.map(function(type){
                                          return <option key={"type_" + type.value} value={type.value}>{type.label}</option>
                                      }, this)
                                  }
                              </select>
                          </div>
                          { this.props.disable && 
                              <input type="hidden" name="usage[tool_type]" value={this.state.tool_type} /> }
                      </div>
  
                      <div className="tool_attribute l-flex__half-col field mbm">
                          <h2 className="sixth-headline mbs">Cutting Diameter</h2>
                          <div className="select">
                              <select id="tool_width" 
                                      disabled={this.props.disable} 
                                      onChange={this.updateCuttingDiameter.bind(this)} 
                                      name="usage[cutting_diameter]" 
                                      value={this.state.cutting_diameter} 
                                      >
                                  <option value="" disabled>Cutting Diameter</option>
                                  {
                                      this.state.cutting_diameters.map(function(diameter){
                                          return <option key={"diameter_" + diameter} value={diameter}>{this.formatValue(diameter)}</option>
                                      }, this)
                                  }
                              </select>
                          </div>
                          { this.props.disable && 
                              <input type="hidden" name="usage[cutting_diameter]" value={this.state.cutting_diameter} /> }
                      </div>
                    </div>

                    <div className="l-flex">
                      <div className="tool_attribute l-flex__half-col field mbm">
                          <h2 className="sixth-headline mbs">Hole Depth</h2>
                          <input  name="usage[total_axial_depth]" 
                                  disabled={!this.state.cutting_diameter} 
                                  readOnly={this.state.tool_type == 'center_drill' || this.state.tool_type == 'countersink'}
                                  value={this.state.total_axial_depth}
                                  pattern="^\d*\.?\d*$"
                                  onChange={this.updateTotalAxialDepth.bind(this)} />
                      </div>                    
  
  
                      <div className="tool_attribute l-flex__half-col field mbm">
                          <h2 className="sixth-headline mbs">Maximum RPM</h2>
                          <input name="usage[max_rpm]" 
                                  disabled={!this.state.cutting_diameter}
                                  value={this.state.max_rpm}
                                  pattern="^\d*\.?\d*$"
                                  onChange={this.updateMaxRPM.bind(this)} />
                      </div>
                  </div>
              </div>
              <div className="col-4 l-shrink-margin--left">
                      <img id="usage_drills_image" src={this.state.image_src} title={this.state.image_title} />
              </div>
         
          </div> 
          </React.Fragment> 
      )
    }
    constructor(props){
        super(props);
      this.state = {
          units:              this.props.units || 'imperial',
          tool_type:  this.props.tool_type || '',
          cutting_diameter:   this.props.cutting_diameter || '',
          total_axial_depth:  this.props.total_axial_depth || '',
          max_rpm:            this.props.max_rpm || '30000',
          image_src:          this.props.tool_type ? '/images/' + this.props.tool_type + '.jpg' : '/images/drill.jpg',
          image_title:        'Drilling',
          loading_diameters:  false,
          cutting_diameters: [],
          drill_types: [
            {label: 'Drill', value: 'drill'},
            {label: 'Center Drill', value: 'center_drill'},
            {label: 'Countersink', value: 'countersink'},
            {label: 'Reamer', value: 'reamer'}
          ],
      }
    }
    updateCuttingDiameter(e){
        this.setState({
            cutting_diameter: e.target.value,
            total_axial_depth: '',
        });
    }
    
    updateToolType(e){
      this.setState({
            tool_type: e.target.value,
            cutting_diameter: '',
            total_axial_depth: '',
          });
    }
  
    updateUnits(e) {
      this.setState({
          units: e.target.value,
          cutting_diameter: '',
        });
    }
  
    setDiameterList(){
      this.setState({ loading_diameters: true});
      fetch(`/api/v1/tools/cutting_diameters.json?type=${encodeURIComponent(this.state.tool_type)}s`)
      .then(response => response.json())
      .then( 
          (data) => {
          var jsx = this;
          this.setState({
              cutting_diameters: _.sortBy(data.filter(function(option) {
                  return  option.units == jsx.state.units
                  }).map(function(option){ return option.cutting_diameter })),
              loading_diameters: false,
              }
          )})
    }
  
    updateTotalAxialDepth(e){
      this.setState({total_axial_depth: e.target.value});
    }
  
    updateMaxRPM(e){
      this.setState({max_rpm: e.target.value});
    }
    
    updateImage(slot){
      this.setState({    
          image_src:   '/images/' + (this.state.tool_type ? this.state.tool_type : 'drill') + '.jpg',
      });
    }
   
    formatValue(value) {
      return this.state.units == 'metric' ? parseFloat(value).toFixed(2) : value;
    }
  
    componentDidMount(){
        if (this.props.tool_type) {
            this.setDiameterList();
        }
        this.activateNext();
    }
  
    componentDidUpdate(prevProps, prevState){
        if (this.state.units != prevState.units  || 
            this.state.tool_type != prevState.tool_type){
                this.setDiameterList();
                this.updateImage();
        }
        this.activateNext();
    }

    activateNext(){
      var next_button = ".button__next";
  
      if (this.state.tool_type &&
          this.state.cutting_diameter &&
          (this.state.total_axial_depth || this.state.tool_type == 'center_drill' || this.state.tool_type == 'countersink') &&
          this.state.max_rpm) {
              $(next_button).addClass('active')
              $(next_button).removeClass('disabled')
          }else{
              $(next_button).removeClass('active')
              $(next_button).addClass('disabled')
          }
    }
}

export default DrillToolCutOptions;