import React from 'react'
import TestToolSectionHeader from 'components/test_tool_report/TestToolSectionHeader'
import TestToolResultRow from 'components/test_tool_report/TestToolResultRow'

export default class TestToolResultsSection extends React.Component{
  handleInputChange(k,v) {
    this.props.onCalcInputChange(k,v);
  }

  render() {
    return  <div className="row ttr-section">
              <TestToolSectionHeader text="Results" />

              <div className="col-10 ttr-section-content">
                <TestToolResultRow data={this.props.data} label="Surface Speed - Feed/Minute" valueType="int" defaultValue="0"
                                   datakey-sgs="result-surface-speed-sgs"
                                   datakey-other="result-surface-speed-other" />

                <TestToolResultRow data={this.props.data} label="Feed - Inches/Minute" valueType="float.1" defaultValue="0.0"
                                   datakey-sgs="result-feed-sgs"
                                   datakey-other="result-feed-other" />

                <TestToolResultRow data={this.props.data} label="Total Parts Available per tool" valueType="float.2" defaultValue="0"
                                   datakey-sgs="result-tool-parts-per-tool-sgs"
                                   datakey-other="result-tool-parts-per-tool-other" />

                <TestToolResultRow data={this.props.data} label="New Tools Required to Complete Job" valueType="int" defaultValue="0"
                                   datakey-sgs="result-num-new-tools-required-sgs"
                                   datakey-other="result-num-new-tools-required-other" />

                <TestToolResultRow data={this.props.data} label="Regrinds Required to Complete Job" valueType="int" defaultValue="0"
                                   datakey-sgs="result-regrinds-required-sgs"
                                   datakey-other="result-regrinds-required-other" />

                <TestToolResultRow data={this.props.data} label="Total Hours of Machining Time" valueType="float.4" defaultValue="0.0000"
                                   datakey-sgs="result-total-hours-machine-time-sgs"
                                   datakey-other="result-total-hours-machine-time-other" />

                <TestToolResultRow data={this.props.data} label="Total Machining Cost" valueType="currency" defaultValue="0.00"
                                   datakey-sgs="result-total-machining-cost-sgs"
                                   datakey-other="result-total-machining-cost-other" />

                <TestToolResultRow data={this.props.data} label="Total New Tool Cost" valueType="currency" defaultValue="0.00"
                                   datakey-sgs="result-total-new-tool-cost-sgs"
                                   datakey-other="result-total-new-tool-cost-other" />

                <TestToolResultRow data={this.props.data} label="Tool Change Cost" valueType="currency" defaultValue="0.00"
                                   datakey-sgs="result-tool-change-cost-sgs"
                                   datakey-other="result-tool-change-cost-other" />

                <TestToolResultRow data={this.props.data} label="Regrind Cost" valueType="currency" defaultValue="0.00"
                                   datakey-sgs="result-regind-cost-sgs"
                                   datakey-other="result-regind-cost-other" />

                <TestToolResultRow data={this.props.data} label="Total Cost" valueType="currency" defaultValue="0.00"
                                   datakey-sgs="result-total-cost-sgs"
                                   datakey-other="result-total-cost-other" />

                <TestToolResultRow data={this.props.data} label="Cost / Part" valueType="currency" defaultValue="0.00"
                                   datakey-sgs="result-cost-per-part-sgs"
                                   datakey-other="result-cost-per-part-other" />

                <TestToolResultRow data={this.props.data} label="Material Removal Rate (in&#x00B3; / min) - Milling" valueType="float.2" defaultValue="0.00"
                                   datakey-sgs="result-mmr-milling-sgs"
                                   datakey-other="result-mmr-milling-other" />

                <TestToolResultRow data={this.props.data} label="MRR Difference - Milling" valueType="float.2" defaultValue="0.00"
                                   datakey-sgs="result-mmr-diff-milling-sgs"
                                   datakey-other="result-mmr-diff-milling-other" />

                <TestToolResultRow data={this.props.data} label="Material Removal Rate (in&#x00B3; / min) - Drilling" valueType="float.2" defaultValue="0.00"
                                   datakey-sgs="result-mmr-drilling-sgs"
                                   datakey-other="result-mmr-drilling-other" />

                <TestToolResultRow data={this.props.data} label="MRR Difference - Drilling" valueType="float.2" defaultValue="0.00"
                                   datakey-sgs="result-mmr-diff-drilling-sgs"
                                   datakey-other="result-mmr-diff-drilling-other" />

                <TestToolResultRow data={this.props.data} label="Cutting Time / Part - Minutes" valueType="float.2" defaultValue="0.00"
                                   datakey-sgs="result-cut-time-per-part-sgs"
                                   datakey-other="result-cut-time-per-part-other"
                                   helpText="Multiply by 60 for cutting time in seconds" />

                <TestToolResultRow data={this.props.data} label="Savings / Part - Minutes" valueType="currency" defaultValue="0.00"
                                   datakey-sgs="result-savings-per-part-sgs"
                                   datakey-other="result-savings-per-part-other" />

                <TestToolResultRow data={this.props.data} label="Total Cost Savings / Job - Percentage" valueType="percent.2" defaultValue="0.00"
                                   datakey-sgs="result-total-savings-percent-sgs"
                                   datakey-other="result-total-savings-percent-other" />

                <TestToolResultRow data={this.props.data} label="Total Cost Savings / Job - Dollars" valueType="currency" defaultValue="0.00"
                                   datakey-sgs="result-total-savings-currency-sgs"
                                   datakey-other="result-total-savings-currency-other" />
              </div>
            </div>
  }
}
