var ToolCutOptions = createReactClass({
  render: function() {
    return <div id="tool" className="l-flex mbn" data-cutting-diameter={this.state.cutting_diameter} data-end-configuration={this.state.end_configuration} >
        { this.state.cutting_diameters.length == 0 &&
          <h2 className="secondary-headline mbs txt-red">Notice: There are no {this.state.tool_type} tools available in {this.state.units} sizes</h2>
        }
        {this.props.type == 'endmill' ? (
          <input type="hidden" name="usage[tool_type]" value="endmill" />
        ) : (
          <div className="tool_attribute l-flex__half-col field mbm">
              <h2 className="sixth-headline mbs">Tool Type</h2>
              <div className="select">
                  <select onChange={this.updateToolType} name="usage[tool_type]" value={this.state.tool_type} defaultValue="">
                      {
                          this.state.drill_types.map(function(type){
                              return <option key={"type_" + type.value} value={type.value}>{type.label}</option>
                          }, this)
                      }
                  </select>
              </div>
          </div>
        )}
        <div className="tool_attribute l-flex__half-col field mbm">
            <h2 className="sixth-headline mbs">Cutting Diameter</h2>
            <div className="select">
                <select id="tool_width" className={$("#usage_slot").is(':checked') && this.props.type == 'endmill' ? 'required' : ''} onChange={this.updateCuttingDiameter} name="usage[cutting_diameter]" value={this.state.cutting_diameter} defaultValue="">
                    <option value="" disabled>Cutting Diameter</option>
                    {
                        this.state.cutting_diameters.map(function(diameter){
                            return <option key={"diameter_" + diameter} value={diameter}>{this.formatValue(diameter)}</option>
                        }, this)
                    }
                </select>
            </div>
        </div>
        {this.props.type == 'endmill' &&
          <div className="tool_attribute l-flex__half-col field mbm">
              <h2 className="sixth-headline mbs">End Configuration</h2>
              <div className="select">
                  <select onChange={this.updateEndConfig} name="usage[end_configuration]" value={this.state.end_configuration} defaultValue="">
                      <option value="" disabled>End Configuration</option>
                      {
                          this.state.end_configs.map(function(config){
                              return <option key={"config_" + config.value} value={config.value}>{config.label}</option>
                          }, this)
                      }
                  </select>
              </div>
          </div>
        }
    </div>
  },
  getInitialState: function(){
    var selected_option = this.props.options.find(function(option) {
      return (option.cutting_diameter == this.props.cutting_diameter &&
        option.units == this.props.units);
    }, this);

    return {
        units: this.props.units,
        cutting_diameter: this.props.cutting_diameter,
        cutting_diameters: _.sortBy(this.props.options.filter(function(option) {
          return  option.units == this.props.units;
        }, this).map(function(option){ return option.cutting_diameter })),
        end_configuration: this.props.end_configuration,
        end_configs: [
          {label: 'Ball Nose', value: 'ball nose'},
          {label: 'Chip Breaker (CR)', value: 'chip breaker (cr)'},
          {label: 'Chip Breaker (Square)', value: 'chip breaker (square)'},
          {label: 'Corner Radius', value: 'corner radius'},
          {label: 'Square End', value: 'square end'},
          {label: 'Tapered (Ball)', value: 'tapered (ball)'},
          {label: 'Tapered (Square)', value: 'tapered (square)'}
        ],
        tool_type: this.props.tool_type || 'drill',
        drill_types: [
          {label: 'Drill', value: 'drill'},
          {label: 'Center Drill', value: 'center_drill'},
          {label: 'Countersink', value: 'countersink'},
          {label: 'Reamer', value: 'reamer'}
        ]
    }
  },
  componentDidMount: function(){
      PubSub.publish('inputs_mounted', true);
      PubSub.subscribe('units_changed', this.unitsChanged);
  },
  updateCuttingDiameter: function(e){
      this.setState({
          cutting_diameter: e.target.value,
      });
  },
  updateEndConfig: function(e){
      this.setState({
          end_configuration: e.target.value,
      });
  },
  updateToolType: function(e){
      var tool_type =  e.target.value,
          self = this;

      this.setState({
          tool_type: tool_type
      });

      $.get("/api/v1/tools/cutting_diameters.json",{ type: tool_type + "s" }, function(data) {
        self.setState({
            cutting_diameters: _.sortBy(data.filter(function(option) {
              return  option.units == self.state.units;
            }).map(function(option){ return option.cutting_diameter }))
        });
      }, "json");

      if(tool_type == "center_drill" || tool_type == "countersink") {
        $("#usage_drills_total_axial_depth").val('');
        $("#usage_drills_total_axial_depth").attr('readonly', 'readonly');
        $("#usage_drills_total_axial_depth").removeClass('required');
      } else {
        $("#usage_drills_total_axial_depth").removeAttr('readonly');
        $("#usage_drills_total_axial_depth").addClass('required');
      }
      $("#usage_drills_image").attr('src', '/images/' + tool_type + '.jpg');
      activateNext();
  },
  unitsChanged: function(msg, units) {
    var self = this;

    if(this.props.type == 'drill') {
      $.get("/api/v1/tools/cutting_diameters.json",{ type: self.state.tool_type + "s" }, function(data) {
        self.setState({
          units: units,
          cutting_diameter: '',
          cutting_diameters: _.sortBy(data.filter(function(option) {
            return  option.units == units;
          }).map(function(option){ return option.cutting_diameter }))
        });
      }, "json");
    } else {
      this.setState({
        units: units,
        cutting_diameter: '',
        cutting_diameters: _.sortBy(this.props.options.filter(function(option) {
          return option.units == units;
        }, this).map(function(option){ return option.cutting_diameter }))
      });
    }
    activateNext();
  },
  formatValue: function(value) {
    return this.state.units == 'metric' ? value.toFixed(2) : value;
  },
})
